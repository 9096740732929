<div class="container g--padding-r-l-0 g--padding-t-45 g--padding-b-40" [ngClass]="{'no-padding-top': dentroModal}">
  <strong class="g--margin-20 title">{{'precliente.politicas-condiciones.lee-acepta' | translate}}</strong>

  <div class="card">
    <div class="card-header">
      <h5 class="card-title">{{'precliente.politicas-condiciones.titulo' | translate}}</h5>
    </div>
    
    <div class="card-body c-text">
      <div class="card-politicasCondiciones">
        <h4><u>{{politicasCondiciones.textos.titulo}}</u></h4>

        <h5>{{politicasCondiciones.textos.responsable}}</h5>

        <p class="g--margin-t-5">{{politicasCondiciones.textos.Identidad}} {{politicasCondiciones.informacion.identidad}}</p>
        <p class="g--margin-t-5">{{politicasCondiciones.textos.direccion}} {{politicasCondiciones.informacion.direccion}}</p>
        <p class="g--margin-t-5">{{politicasCondiciones.textos.telefono}} {{politicasCondiciones.informacion.telefono}} -
           {{politicasCondiciones.textos.email}} {{politicasCondiciones.informacion.email}}</p>
        
        <p class="g--margin-t-40">{{politicasCondiciones.textos.politica_1}}</p><br>
        <p>{{politicasCondiciones.textos.politica_2}}</p><br>
      </div>
    </div>
    
    <div class="card-footer">
      <div class="form-check">
        <!-- Newsletter -->   
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="flexCheckDefault" [(ngModel)]="clienteFicha.Newsletter">
          <label class="align-checkbox-label" for="flexCheckDefault">
            {{"precliente.formulario.marcar-newsletter" | translate}}
          </label>
        </div>
        
        <div class="botones-condiciones d-flex justify-content-first g--margin-t-30">
          <app-boton [lectura]="lectura" clase="btn btn-outline-primary me-2" (click)="noAceptoCondiciones()" [nombre]="'precliente.politicas-condiciones.boton.no-acepto' | translate"></app-boton>
          <app-boton [lectura]="lectura" clase="btn btn-primary" (click)="aceptoPoliticas()" [nombre]="'precliente.politicas-condiciones.boton.acepto-terminos'"></app-boton>
        </div>
  </div>

