import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ETipoPreferencia } from 'src/app/models/enums.enum';
import { IPreferencia } from 'src/app/models/interfaces-apis/diccionarios';
import { ObservacionesComponent } from '../../../form-componentes/observaciones/observaciones.component';
import { IFichaCliente, IPreferenciaCliente } from 'src/app/models/interfaces-apis/ficha_cliente';
import { preferencias } from 'src/app/models/General/preferencias';
import { GrupoPreferenciaComponent } from '../../../form-componentes/grupo-preferencia/grupo-preferencia.component';

@Component({
  selector: 'app-preferencia-tren',
  templateUrl: './preferencia-tren.component.html',
  styleUrls: ['./preferencia-tren.component.scss']
})
export class PreferenciaTrenComponent extends preferencias implements OnInit {

  @ViewChild('Grupo_Preferencia_Tren_Asientos') Grupo_Preferencia_Tren_Asientos: GrupoPreferenciaComponent;
  @ViewChild('Grupo_Preferencia_Tren_Mesa') Grupo_Preferencia_Tren_Mesa: GrupoPreferenciaComponent;
  @ViewChild('Grupo_Preferencia_Tren_SentidoMarcha') Grupo_Preferencia_Tren_SentidoMarcha: GrupoPreferenciaComponent;
  @ViewChild('Grupo_Preferencia_Tren_Observaciones') Grupo_Preferencia_Tren_Observaciones: ObservacionesComponent;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  TipoPreferencia = ETipoPreferencia;

  @Input() preferencias: IPreferencia[];

  @Input() clienteFicha: IFichaCliente;


  //Obtener la lista de las preferencias tren guardadas
  obtenerPreferenciasGuardadas(): IPreferenciaCliente[] {

    let ListaPreferencias: IPreferenciaCliente[] = []

    let tuplaTrenPreferente = this.Grupo_Preferencia_Tren_Asientos.obtenerPreferencias()
    let preferenciaTrenAsientos: IPreferenciaCliente = {
      Tipo: ETipoPreferencia.PreferenciaAsientosTren,
      Preferencias: tuplaTrenPreferente[0],
      InfoEspecifica: tuplaTrenPreferente[1]
    }
    ListaPreferencias.push(preferenciaTrenAsientos)

    let tuplaTrenPreferenteMesa = this.Grupo_Preferencia_Tren_Asientos.obtenerPreferencias()
    let PreferenciaMesaTren: IPreferenciaCliente = {
      Tipo: ETipoPreferencia.PreferenciaMesaTren,
      Preferencias: tuplaTrenPreferenteMesa[0],
      InfoEspecifica: tuplaTrenPreferenteMesa[1]
    }
    ListaPreferencias.push(PreferenciaMesaTren)

   

    let tuplaTrenSentidoMarcha = this.Grupo_Preferencia_Tren_SentidoMarcha.obtenerPreferencias()
    let preferenciaTrenSentidoMarcha: IPreferenciaCliente = {
      Tipo: ETipoPreferencia.SentidoMarchaTren,
      Preferencias: tuplaTrenSentidoMarcha[0],
      InfoEspecifica: tuplaTrenSentidoMarcha[1]
    }
    ListaPreferencias.push(preferenciaTrenSentidoMarcha)

    let textoObservacion = this.Grupo_Preferencia_Tren_Observaciones.obtenerPreferencias()
    let preferenciaTrenObservaciones: IPreferenciaCliente = {
      Tipo: ETipoPreferencia.ObservacionTren,
      Preferencias: null,
      InfoEspecifica: textoObservacion,
    }
    ListaPreferencias.push(preferenciaTrenObservaciones)

    return ListaPreferencias;

  }

  

  //Actualziar preferencias tren
  actualizar() {
    let preferencias = this.clienteFicha.Preferencias
    let preferenciaPreferenciaAsientosTren: IPreferenciaCliente = this.obtenerPreferenciasCliente(preferencias, ETipoPreferencia.PreferenciaAsientosTren)
    let preferenciaSentidoMarchaTren: IPreferenciaCliente = this.obtenerPreferenciasCliente(preferencias, ETipoPreferencia.SentidoMarchaTren)
    let preferenciaTextoObservacionTren: IPreferenciaCliente = this.obtenerPreferenciasCliente(preferencias, ETipoPreferencia.ObservacionTren)

    this.Grupo_Preferencia_Tren_Asientos.actualizar(preferenciaPreferenciaAsientosTren?.Preferencias);
    this.Grupo_Preferencia_Tren_SentidoMarcha.actualizar(preferenciaSentidoMarchaTren?.Preferencias);
    this.Grupo_Preferencia_Tren_Observaciones.actualizar(preferenciaTextoObservacionTren?.InfoEspecifica);
  }

  //Valdiar preferencias tren
  validarPreferencias() {
    let estadoTrenPreferenciaAsiento = this.Grupo_Preferencia_Tren_Asientos.validarPreferencias()
    let estadoTrenSentidoMarcha = this.Grupo_Preferencia_Tren_SentidoMarcha.validarPreferencias()

    if (estadoTrenPreferenciaAsiento && estadoTrenSentidoMarcha) {
      return true;
    }
    return false;
  }
}

