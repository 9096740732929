import { Input } from "@angular/core";
import { IPreferencia } from "../interfaces-apis/diccionarios";
import { IPreferenciaCliente } from "../interfaces-apis/ficha_cliente";

export class preferencias {


    obtenerOpcionesPreferencia(preferencias: any, id: number) {
        let listadoOpciones = []
        if (preferencias) {
            let encontrado = this.obtenerPreferenciasDiccionario(preferencias, id);
            listadoOpciones = encontrado ? encontrado.Opciones : [];
        }
        return listadoOpciones;
    };

    obtenerCamposMultiplesPreferencia(preferencias: any,id: number) {
        let encontrado = this.obtenerPreferenciasDiccionario(preferencias,id);
        
        return encontrado ? encontrado.SeleccionMultiple : false;
        
    };

    obtenerCamposObligatoriosPreferencia(preferencias: any,id: number) {
        
        let encontrado = this.obtenerPreferenciasDiccionario(preferencias,id);
        
        return encontrado ? encontrado.Obligatorio : false;
        
    
    };

    private obtenerPreferenciasDiccionario(preferencias: any,id: number) {
        if (preferencias) {
            return preferencias.find((tipo: { Id: number; }) => tipo.Id == id);
        }
        return null;
    }

    obtenerPreferenciasCliente(preferencias: IPreferenciaCliente [],id: number) {
        if (preferencias) {
            return preferencias.find((tipo: { Tipo: number; }) => tipo.Tipo == id);
        }
        return null;
    }

    public texto:string = '';

     public actualizarValorObservacion(texto: string){
        this.texto = texto;
      }

   

}