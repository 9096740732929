import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class ServicioGeneralService {

  idioma: string;
  lenguajes = ['es-ES', 'en-GB', 'pt-PT', 'fr-FR', 'de-DE'];

  private _lenguaje = new BehaviorSubject<string>(

    (localStorage.getItem('lang')) ? localStorage.getItem('lang')!.toString() : 'es-ES'
    
  );
  lenguaje$ = this._lenguaje.asObservable();


  constructor(
    private _httpService: HttpClient,
    private _translate: TranslateService
    
  ) {
    if(localStorage.getItem('lang') == null){
      localStorage.setItem('lang', 'es-ES');
    }   
  }

  async setLenguaje(lenguaje: string, reload = true) {
    lenguaje = this.comprobarLenguaje(lenguaje);
    const lenguajeActual = localStorage.getItem('lang');

    if (lenguaje != lenguajeActual) {
      localStorage.setItem('lang', lenguaje);

      if (reload) {
        window.location.reload();
      } else {
        this._lenguaje.next(lenguaje);
      }
    }
  }

  getLenguaje() {
    return this._lenguaje.value;
  }

  comprobarLenguaje(lenguajeComprobar: string) {
    lenguajeComprobar = lenguajeComprobar.replace('_', '-');

    switch (lenguajeComprobar) {
      case 'pt':
      case 'pt-PT':
        this.idioma = 'pt-PT'
        break;

      case 'en':
      case 'en-GB':
        this.idioma = 'en-GB'
        break;

      case 'fr':
      case 'fr-FR':
        this.idioma = 'fr-FR'
        break;

      case 'de':
      case 'de-DE':
        this.idioma = 'de-DE'
        break;

      default:
        this.idioma = 'es-ES'
        break;
    }
    return this.lenguajes.find((lenguaje) => lenguajeComprobar == lenguaje) ? lenguajeComprobar : this.lenguajes[0];
  }

  informeErrores(errorRAW: number) {
    let error: string = "";
    switch (errorRAW) {
      case 0:
        error = 'auto-reserva.vuelos.comun.error.servidor';
        break;
      case 1:
        error = 'Documento duplicado.';
        break;
      case 2:
        error = 'Asegurese que los datos son correctos:';
        break;
      case 3:
        error = 'Documento caducado';
        break;
      case 4:
        error = 'Solo puede tener un documento de familias númerosas o residente asociado a un mismo país.';
        break;
      case 5:
        error = 'Debe generar un documento de identificación para poder guardar los cambios';
        break;
      case 6:
        error = 'Debe rellenar el país de nacionalidad de forma obligatoria.';
        break;
      case 7:
        error = 'El DNI no es valido.';
        break;
      case 8:
        error = 'La fecha de expedición no puede ser posterior a la fecha de caducidad';
        break;
      case 9:
        error = 'Debe agregar el código en su tajeta de fidelización.';
        break;
      case 10:
        error = 'Debe seleccionar por lo menos un tipo para poder asociarlo a la tarjeta de fidelización.';
        break;
      case 11:
        error = 'No puede tener dos documentos con el mismo país de expedición (España).';
        break;
      case 12:
        error = 'Número de documento';
        break;
      case 13:
        error = 'Fecha de expedición';
        break;
      case 14:
        error = 'Fecha de caducidad';
        break;
      case 15:
        error = 'La fecha de expedición no puede ser posterior a la fecha de actual';
        break;
      case 400:
        error = 'auto-reserva.vuelos.comun.error.servidor';
        break;
      case 401:
        error = 'Acceso denegado';
        break;
      case 403:
        error = 'Se detectaron demasiados intentos de inicio de sesión fallidos, por favor espere unos minutos e intentelo de nuevo.';
        break;
      case 404:
        error = 'admin.comun.error-recurso';
        break;
      default:
        error = 'auto-reserva.vuelos.comun.error.indefinido';
        break;
    }
    return error
  }
  mostrarErrores(tipo: number, errores: string) {
    let icono: any;
    switch (tipo) {
      case 1:
      case 6:
        icono = 'warning'
        break;
      case 3:
      case 4:
        icono = 'info'
        break;
      case 2:
      case 5:
        icono = 'error'
        break;

      default:
        icono = 'error'
        break;
    }
    if (tipo == 2 || tipo == 5 || tipo > 5) {
//localstorage para el rol

      Swal.fire({
        icon: icono,
        html: '<ul>' + this._translate.instant(errores) + '</ul>',
        title: `${this._translate.instant('auto-reserva.componentes-comunes.un-momento')}...`,
        confirmButtonText: this._translate.instant('auto-reserva.vuelos.comun.vale'),
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then((result) => {
        if (result.isConfirmed) {
          let rol=localStorage.getItem('rol');
          if (rol == "2") {
            location.href = "./login";
          }else if(rol == "3"){
            location.href = "./loginAdmin";
          }
          
        };
      });
    } else {
      Swal.fire({
        icon: icono,
        html: '<ul>' + this._translate.instant(errores) + '</ul>',
        title: `${this._translate.instant('auto-reserva.componentes-comunes.un-momento')}...`,
        confirmButtonText: this._translate.instant('auto-reserva.vuelos.comun.vale'),
        allowOutsideClick: false,
        allowEscapeKey: false
      })
    };
  }
  cerrarSesion(nombre: string) {
    Swal.fire({
      title: this._translate.instant('admin.comun.atencion'),
      html: "<div class=g--margin-t-15><b>" + nombre + "</b></div><div class=g--margin-t-15>" +
        "¿Confirmas el cierre de sesión?</div>",
      icon: 'warning',
      showDenyButton: true,
      confirmButtonColor: '#3085d6',
      denyButtonColor: '#d33',
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonText: '<i class="pi pi-chevron-left g--margin-r-10"></i>' + this._translate.instant('auto-reserva.hoteles.prereserva.volver'),
      denyButtonText: '<i class="pi pi-sign-out g--margin-r-10"></i>' + this._translate.instant('admin.comun.confirmar'),
    }).then((result) => {
      if (result.isDenied) {
        location.href = "./login";
      };
    })
  };

  validarNIE(numeroID): boolean {
    return /^[xyzXYZ][0-9]{7}[a-zA-z]$/.test(numeroID);
  }
  
  validarDNI(numeroID): boolean {
    return /^[0-9]{8}[a-zA-z]$/.test(numeroID);
  }
}
