import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-boton',
  templateUrl: './boton.component.html',
  styleUrls: ['./boton.component.scss']
})

/**
 * Componente que permite generar botones
 */
export class BotonComponent
{
  //#region INPUTS

  /**
   * Nombre que tendra el boton
   */
  @Input() nombre: string;

  /**
   * Nombre de la clase que tendra el boton
   */
  @Input() clase: string;

  /**
   * Indica si se usa icono
   */
  @Input() usaIcono: boolean = false;

  /**
   * Icono que se mostrara
   */
  @Input() claseIcono: string = '';

  @Input() deshabilitado: boolean = false;
  
  @Input() lectura: boolean = false;

  //#endregion

}
