import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cond-generales',
  templateUrl: './cond-generales.component.html',
  styleUrls: ['./cond-generales.component.scss']
})
export class CondGeneralesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  //URL para mostrar el logo que tenemos almacenada dentro de recursos
  urlLogo = environment.recursos + '/nuevoestiloweb/logos/logo_tipos_destinux.svg';
}
