<div class="g--margin-t-30 c-section-altnative c-table c-text c-text--gd"  [class] ="ClaseAdicional">
    <div class="c-section__header">
        <b>{{TituloGrupo | translate}}</b>
        <b *ngIf ="required"   class="mensaje-obligatorio">
        <span class="mensaje-texto">{{'Preferencia Obligatoria'| translate}}</span>
        <span class="asterisco-rojo">*</span>
        </b>
    </div>
    <div class="c-section__body g--margin-t-40">
        <app-checkbox-primeNG [multiple]="multiple"
        [obtenerOpcionesPreferencia]="ListaPreferencias"
        sectionClass="g--width-100"
        (opcionesSeleccionadasEvent)="ListaOpcionesMarcadas($event)"
        ></app-checkbox-primeNG>
        <div [hidden]="!RequiereObservacion || !isChecked9"  class="c-section">
            <div class="c-section__body">
            <app-observaciones
            [(textareaValue)] = "texto"
            (textareaValueChange)="actualizarValorObservacion($event)"
            ></app-observaciones>
            </div>
        </div>
        <ng-content></ng-content>
    </div>
</div>
