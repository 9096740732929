import { Component, Inject, Renderer2 } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { IMAGEN_LOGIN_FONDO, IMAGEN_LOGIN_FOOTER } from 'src/app/models/constantes';
import { AuthService } from 'src/app/servicios/auth.service';
import { ILoginAdmin } from 'src/app/models/interfaces-apis/auth';
import { EMensaje, ETipoAlerta } from 'src/app/models/enums.enum';
import { AlertaService } from 'src/app/shared/alerta.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-login-admin',
  templateUrl: './login-admin.component.html',
  styleUrls: ['./login-admin.component.scss']
})

/**
 * Componente login que permite realizar el proceso de inicio de sesion de los administradores
 */
export class LoginAdminComponent
{

  //#region VARIABLES

  /**
  * Formulario
  */
  form: any;

  /**
  * Imagen para el footer
  */
  imagenFooter = IMAGEN_LOGIN_FOOTER;

  /**
  * Peticion que contiene la estructura necesaria para llamar a la api
  */
  #peticion: ILoginAdmin =
  {
    Correo: null,
    Password: null
  };


  //#endregion


  /**
   * Constructor del componente
   * @param _authService: Servicio que permite realizar las llamadas a la api
   * @param _formBuilder: Manejador del formulario
   * @param _alertaService: Servicio de alerta
   */
  constructor(private renderer: Renderer2, @Inject(DOCUMENT) private document: Document,
    private _authService: AuthService,
    private readonly _formBuilder: FormBuilder,
    private _alertaService: AlertaService
  )
  {
    this.inicializaFormulario()
  }

  //Hacemos que en el Login haya un cambio en el body y asi mostrar la imagen de fondo de pantalla
  ngOnInit() {
    this.renderer.setStyle(this.document.body, 'background-image', `url(${IMAGEN_LOGIN_FONDO})`);

}
  //#region METODO PUBLICOS

  /**
   * Método que permite enviar el formulario para realizar el inicio de sesion
   */
  enviarFormulario()
  {
    if (this.form.valid)
    {
      // Se establecen los datos de la peticion
      this.establecerPeticion();
      // Se realiza la llamada al login
      this.login(this.#peticion);
    }
    else
    {
      // Se informa al usuario que los datos de la peticion no son validos
      this._alertaService.mostrarAlerta(EMensaje.ComprobarPeticion, ETipoAlerta.Info)
    };
  }

  //#endregion


  //#region METODOS PRIVADOS

  /**
   * Método que permite inicializar el formulario
   */
  private inicializaFormulario()
  {
    this.form = this._formBuilder.group({
      correo: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.maxLength(60)]],
    });
  }

  /**
   * Método que realiza el login
   * @param peticion: Peticion necesaria para hacer login
   */
  private login(peticion: ILoginAdmin)
  {
    this._authService.loginAdmin(peticion).subscribe({
      next: (rol: any) => {
        localStorage.setItem('rol', rol.toString());
        localStorage.setItem('user_token', this.#peticion.Password);
        location.href = './administracion'
      },
    });
  }

  /**
   * Método que permite crear la petición
   */
  private establecerPeticion()
  {
    this.#peticion.Correo = this.form.value.correo;
    this.#peticion.Password = this.form.value.password;
  }

  //#endregion

}
