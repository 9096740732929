import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { EMensaje, ERol, ERuta, ETipoAlerta } from 'src/app/models/enums.enum';
import { UserService } from 'src/app/servicios/user.service';
import { AlertaService } from 'src/app/shared/alerta.service';
import { Util } from 'src/app/shared/util';

@Injectable({
  providedIn: 'root'
})
export class GuardFichaGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  //Guard para no poder acceder a la ficha en caso de no tener el rol correcto
  constructor(private _userService: UserService, private _alertaService: AlertaService) {
    let ruta = window.location.pathname;
    let partes = ruta.split("/ficha-cliente/");
    let token = partes[1];
    localStorage.setItem('user_token', token); //TODO Establecer token en user_token
    let rol = parseInt(localStorage.getItem('rol'));
    if (!Util.validarGUID(token) || rol != ERol.Usuario) {
      this._alertaService.mostrarAlerta(EMensaje.RolNoPermitido, ETipoAlerta.Error, ERuta.Login)

    }

  }

  
}

