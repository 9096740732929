import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Util } from 'src/app/shared/util';

export const MY_FORMATS = {
  parse: {    dateInput: 'DD MM YYYY',
  },  display: {    dateInput: 'DD MM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD MM YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-calendar-material',
  templateUrl: './calendar-material.component.html',
  styleUrls: ['./calendar-material.component.scss'],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})


/**
 * Componente que permite generar input de material
 */
export class CalendarMaterialComponent
{

  //#region INPUTS

  @Input() form: FormGroup;

  /**
   * Indica si el campo sera lectura
   */
  @Input() lectura: boolean = false

  /**
   * Vaciar la fecha para los calendarios
   */
  @Input() vaciarFecha

  /**
   * Indica si se mostrare el boton
   */
  @Input() mostrarBoton: boolean = false
  
  /**
   * Indica la id que tendra el input
   */
  @Input() id: string;

  /**
   * Control del formulario
   */
  @Input() control: FormControl;

  /**
   * Indica el nombre que tendra el input
   */
  @Input() nombre: string;

  /**
   * Indica el nombre que tendra el label
   */
  @Input() label: string;

  /**
   * Indica la fecha minima a seleccionar
   */
  @Input() min: Date | undefined;

  /**
   * Indica la fecha maxima a seleccionar
   */
  @Input() max: Date | undefined;

  /**
   * Indica si el input es requerido o no
   */
  @Input() isRequired: boolean = false;

  /**
   * Indica si el input esta deshabilitado o no
   */
  @Input() isDisabled: boolean = false;

 /**
   * Indica campo lectura que tendra el input
   */
 @Input() readonly: boolean;

  /**
   * Indica campo lectura que tendra el input
   */
 @Input() disabled: boolean = false;
  //#endregion

  /**
   * Clase para el ancho que tendra el input
   */
  @Input() widthClass: string = '';

  /**
   * Indica si se mostrara el icono del calendario o no 
   */
  @Input() mostrarIconoCalendario: boolean = false

  /**
   * Método que permite formatear la fecha
   * @param event Evento del input fecha
   * @returns Fecha formateada
   */
  formatearFecha(event: MatDatepickerInputEvent<Date>)
  {
    return Util.obtenerFechaDesdeControl(event.value)
  };

  /**
   * Funcion para poder vaciar la fecha
   */
  vaciar() {
    if(this.vaciarFecha){
      this.vaciarFecha(this.form)
    }
  }
}
