import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IBandera } from 'src/app/models/General/bandera';
import { ServicioGeneralService } from 'src/app/servicios/servicio-general.service';

@Component({
  selector: 'app-banderas',
  templateUrl: './banderas.component.html',
  styleUrls: ['./banderas.component.scss']
})

/**
 * Componente que permite gestionar la selección del idioma de la web
 */
export class BanderasComponent implements OnInit, OnDestroy {

  @Input() reload = false;
  @Input() tipo!: string;

  // Lista de banderas para los idiomas soportados
  public banderas: IBandera[] = [
    {
      lenguaje: 'es-ES',
      texto: 'Español',
      nombreIMG: 'espana'
    },
    {
      lenguaje: 'en-GB',
      texto: 'English',
      nombreIMG: 'reino-unido'
    },
    {
      lenguaje: 'pt-PT',
      texto: 'Português',
      nombreIMG: 'portugal'
    },
    {
      lenguaje: 'fr-FR',
      texto: 'Français',
      nombreIMG: 'francia'
    },
    {
      lenguaje: 'de-DE',
      texto: 'Deutsch',
      nombreIMG: 'alemania'
    }
  ];

  banderaSeleccionada!: IBandera;
  _peticion!: Subscription;

  /**
   * Constructor del componente
   * @param servicioGeneral: Servicio que permite obtener los idiomas soportados
   */
  constructor(private servicioGeneral: ServicioGeneralService) {}

  /**
   * Inicio de componente
   */
  ngOnInit() {
    this._peticion = this.servicioGeneral.lenguaje$.subscribe((lenguaje) => {
      this.banderaSeleccionada = this.banderas.find((bandera) => bandera.lenguaje == lenguaje)!;
    });
  }

  /**
   * Destruccion del componente
   */
  ngOnDestroy() {
    this._peticion.unsubscribe();
  }

  /**
   * Método que permite realizar el cambio del idioma en la web
   * @param lenguaje: Lenguaje seleccionado
   */
  cambiarLenguaje(lenguaje: string) {
    this.servicioGeneral.setLenguaje(lenguaje, this.reload);
  }
}



