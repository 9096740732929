import { Injectable } from '@angular/core';
import {HttpHandler, HttpRequest, HttpInterceptor, HttpEvent, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { ControlErrorService } from '../shared/control-errores.service';
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})

/**
 * Clase que permite interceptar los errores del servicio HTTP
 */
export class HttpErrorInterceptor implements HttpInterceptor
{

  /**
   * Constructor del interceptor
   * @param _errorService: Servicio encargado de gestionar los errores
   */
  constructor(private _errorService: ControlErrorService) {}

  /**
   * Interceptador que permite gestionar los errores
   * @param req Request de la solicitud
   * @param next Controlador de evento
   * @returns Error
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
  {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) =>
      {

        // Si el error es del lado del cliente
        if (error.error instanceof ErrorEvent)
        {
          this._errorService.errorCliente(error.error.message);
        }
        // Si el error es del lado del servidor
        else
        {
          this._errorService.errorServidor(error);
        }

        return throwError(error);
      })
    );
  }
}
