<div class="c-body c-body--fondo">
  <div class="container text-center">

    <!-- Imagen Destinux -->
    <app-imagen-destinux></app-imagen-destinux>

    <!-- Formulario login admin -->
    <div class="row justify-content-center mt-5 mt-md-3 mt-lg-5">
      <div class="col-lg-4 col-md-6 col-sm-10">

        <form [formGroup]="form">

          <!-- Imput Email -->
          <app-input-group
            id="correo"
            type="email"
            nombre="correo"
            clase="form-control input-icono"
            claseSpan="input-group-text label-icono"
            nombreIcono="email"
            icono="g--color-blue-icon-7"
            [control]="form.get('correo')"
            placeholder="{{'precliente.login.label.porfavorcorreo' | translate }}"
            [isRequired]="true"
          ></app-input-group>

          <!-- Imput Clave -->
          <app-input-group
            id="password"
            type="password"
            nombre="password"
            clase="form-control input-icono mt-2"
            claseSpan="input-group-text label-icono mt-2"
            nombreIcono="lock_open"
            icono="g--color-blue-icon-7"
            [control]="form.get('password')"
            placeholder="{{'login.contrasenya' | translate }}"
            [isRequired]="true"
          ></app-input-group>

          <div class="mt-3 l-flex l-flex--space-between">

            <!-- Seleccion de idioma -->
            <app-banderas tipo="dropdown"></app-banderas>

            <!-- Boton enviar formulario -->
            <app-boton
              clase="c-button c-button--transparent c-text g--padding-16"
              [nombre]="'precliente.login.boton.enviar'"
              (click)="enviarFormulario()"
            ></app-boton>

          </div>

        </form>

      </div>
    </div>

    <!-- Imagen footer -->
    <app-imagen-footer-login [anyadirClasePy]="true"></app-imagen-footer-login>

  </div>
</div>
