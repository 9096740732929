<mat-form-field class="w-100"appearance="outline"  class="custom-outline" [ngClass]="widthClass">

  <span matPrefix>
    <i [class]="claseIcono"></i>
  </span>

  <mat-label class="c-text c-text--gd">{{label}}</mat-label>

  <input matInput class="c-input"
    [id]="id"
    [name]="nombre"
    [placeholder]="placeholder"
    [ngClass]="clase"
    [required]="isRequired"
    [type]="type"
    [formControl]="control"
    [readonly]="readonly"
  >

</mat-form-field>
