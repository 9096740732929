import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild,  } from '@angular/core';
import { FormControl } from '@angular/forms';
import { preferencias } from 'src/app/models/General/preferencias';
import { IItem } from 'src/app/models/interfaces-apis/diccionarios';

@Component({
  selector: 'app-select-search-material',
  templateUrl: './select-search-material.component.html',
  styleUrls: ['./select-search-material.component.scss']
})


/**
 * Componente que permite generar inputs de tipo select con buscador
 */
export class SelectSearchMaterialComponent extends preferencias implements OnInit
{

  @ViewChild('focoBuscador') focoBuscador: ElementRef;

  //#region INPUTS

  /**
   * Indica si tiene el buscador activo
   */
  @Input() buscador: boolean = true;

  /**
   * Indica si soporta seleccion multiple
   */
  @Input() multiple: boolean = false;
/**
 * 
   * Indica si el campo es obligatorio
   */
  @Input() required: boolean = false;

  /**
   * Lista de opciones a mostrar en el selector
   */
  @Input() opciones: IItem[] = [];

  /**
   * Indica la id que tendra el input
   */
  @Input() id: string;

  /**
   * Control del formulario
   */
  @Input() control: FormControl;

  /**
   * Indica la clase que tendra el input
   */
  @Input() clase: string;

  
  /**
   * Indica el label que tendra el input
   */
  @Input() label: string;

   /**
   * Indica la clase que tendra el icono
   */
   @Input() claseIcono: string;

  /**
   * Indica el placeholder del input text
   */
  @Input() placeholderBuscador: string;

  /**
   * Indica el placeholder del input select
   */
  @Input() placeholderSelector: string;
  
   /**
   * Indica si es solo modo lectura del input select
   */
   @Input() readonly: boolean;

  //#endregion

  @Output() opcionSeleccionadaEvent = new EventEmitter<string>();

  /**
   * Texto ingresado por el usuario que permite filtrar el selector
   */
  textoBusqueda: string;

  /**
   * Clave que se encuentra seleccionado
   */
  seleccionada: any = [];

  /**
   * Indica si el input es requerido o no
   */
  @Input() isRequired: boolean = false;

  /**
   * Clase para el ancho que tendra el input
   */
  @Input() widthClass: string = '';


  /**
   * Carga del componente
   */
  ngOnInit()
  {
    this.seleccionada = this.control?.value;
  }

  seleccionados = [];

  
  /**
   * Nos permite que al abrir un select se ponga el foco en el buscador
   */
  focoEnBuscador(isOpen: boolean) {
    if (isOpen && this.focoBuscador) {
      setTimeout(() => {
        this.focoBuscador.nativeElement.focus();
      });
    }
  }

  /**
   * Método que permite establecer en el control la opcion seleccionada por el usuario
   * @param clave Clave de la opcion seleccioanda
   */
  public opcionSeleccionada(clave: any)
  {
    if (this.multiple)
    {
      // Obtener los valores seleccionados actuales
      if(this.control){
         this.seleccionados = this.control.value || [];
      }
      const index = this.seleccionados.indexOf(clave);

      if (index >= 0)
      {
        // Desseleccionar si ya estaba seleccionado
        this.seleccionados.splice(index, 1);
      }
      else
      {
        // Seleccionar si no estaba en la lista
        this.seleccionados.push(clave);
      }

      // Actualizar el valor del control
      if(this.control){
        this.control.setValue(this.seleccionados);
      }
    }
    else
    {
      // Seleccion unica
      if(this.control){
        this.control.setValue(clave);
      }else{
      this.seleccionada.push(clave);
      }
    }

    this.opcionSeleccionadaEvent.emit(this.seleccionada);
  }

  actualizar()
  {
    this.seleccionada = this.control.value;
  }

  actualizarSeleccion(lista: any){
    this.seleccionada = lista
  }

  obtenerPreferencias(): string[] {
    return this.seleccionada;
}

  /**
   * Validar preferencias seleccionadas
   */
  validarPreferencias(){
    if(this.required && this.seleccionada.length == 0){
      return false
    }
    return true
  }



}
