
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ETipoPreferencia } from 'src/app/models/enums.enum';
import { IPreferencia } from 'src/app/models/interfaces-apis/diccionarios';
import { ObservacionesComponent } from '../../../form-componentes/observaciones/observaciones.component';
import { IFichaCliente, IPreferenciaCliente } from 'src/app/models/interfaces-apis/ficha_cliente';
import { preferencias } from 'src/app/models/General/preferencias';
import { GrupoPreferenciaComponent } from '../../../form-componentes/grupo-preferencia/grupo-preferencia.component';
@Component({
  selector: 'app-preferencias-transfer',
  templateUrl: './preferencias-transfer.component.html',
  styleUrls: ['./preferencias-transfer.component.scss']
})
export class PreferenciasTransferComponent extends preferencias implements OnInit {


  @ViewChild('Grupo_Preferencia_Transfer_Observaciones') Grupo_Preferencia_Transfer_Observaciones: ObservacionesComponent;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  TipoPreferencia = ETipoPreferencia;

  @Input() preferencias: IPreferencia[];

  @Input() clienteFicha: IFichaCliente;


  //Obtener la lista de las preferencias transfer guardadas
  obtenerPreferenciasGuardadas(): IPreferenciaCliente[] {

    let ListaPreferencias: IPreferenciaCliente[] = []

    let textoObservacion = this.Grupo_Preferencia_Transfer_Observaciones.obtenerPreferencias()
    let preferenciaTransferObservaciones: IPreferenciaCliente = {
      Tipo: ETipoPreferencia.ObservacionTransfer,
      Preferencias: null,
      InfoEspecifica: textoObservacion,
    }
    ListaPreferencias.push(preferenciaTransferObservaciones)

    return ListaPreferencias;

  }

  //Actualizar preferencias transfer
  actualizar() {
    let preferencias = this.clienteFicha.Preferencias

    let preferenciaTextoObservacionTransfer: IPreferenciaCliente = this.obtenerPreferenciasCliente(preferencias, ETipoPreferencia.ObservacionTransfer)

    this.Grupo_Preferencia_Transfer_Observaciones.actualizar(preferenciaTextoObservacionTransfer?.InfoEspecifica);
  }


}

