import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ServicioGeneralService } from './servicios/servicio-general.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

/**
 * Componente principal de la aplicación
 */
export class AppComponent
{

  title = 'gestion-fichas-usuario';

  /**
   * Constructor del componente principal
   * @param _servicioGeneral: Servicio general
   * @param _translate: Servicio de traducciones
   */
  constructor(private _servicioGeneral:ServicioGeneralService, private _translate: TranslateService)
  {
    this.suscribirLenguaje()
  }


  /**
   * Método que permite habilitar el uso de las traducciones
   */
  suscribirLenguaje()
  {
    this._servicioGeneral.lenguaje$.subscribe((lenguaje) =>
    {
      this._translate.use(lenguaje);
    });
  }

}
