import { Component } from '@angular/core';
import { IMAGEN_DESTINUX } from '../../../models/constantes';

@Component({
  selector: 'app-imagen-destinux',
  templateUrl: './imagen-destinux.component.html',
  styleUrls: ['./imagen-destinux.component.scss']
})

/**
 * Componente que permite pintar la imagen de destinux
 */
export class ImagenDestinuxComponent
{
  /**
   * Ruta a la imagen de destinux
   */
  imagen = IMAGEN_DESTINUX;

}
