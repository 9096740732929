import { IItem } from "../models/interfaces-apis/diccionarios";


/**
 * Clase que permite definir las conversiones de tipos a item
 */
export class ItemConverter
{

  /**
   * Método que permite convertir una lista de objetos a una lista de items
   * @param objeto Lista de objeto a convertir en lista de item
   * @param variableClave Clave a utilizar
   * @param variableValor Valor a utilizar
   * @returns Lista de Item
   */
  static transformarObjetoToItem<T>(objeto: T[], variableClave: string, variableValor: string): IItem[]
  {
    const item : IItem[] = [];
    objeto.forEach((obj) =>
    {
      let Clave: any;
      let Valor: any;

      // Se obtiene la clave y valor del objeto
      for (const ob in obj)
      {
        if (ob == variableClave)
        {
          Clave = obj[ob];
        }

        if (ob == variableValor)
        {
          
          if(variableValor == "prefix"){
            Valor = obj["name"] + " +" + obj[ob];
          }
          else if(variableValor == "Localidad"){
            Valor = obj["Provincia"] + " - " + obj["Localidad"] + " - " + obj["Codigo"];
          }
          else{
            Valor = obj[ob];
          }
        }
      }

      item.push({Clave, Valor});
    });

    return item;
  }


  /**
   * Método que permite convertir una lista de array a una lista de items
   * @param ListaArray Lista de array a convertir en lista de item
   * @returns Lista de Item
   */
  static transformarArrayToItem<T>(objeto: any[]): IItem[]
  {
    const item : IItem[] = [];
    objeto.forEach((valor) =>
    {
      let Clave: string = valor;
      let Valor: string = valor;

      item.push({Clave, Valor});
    });

    return item;
  }

}
