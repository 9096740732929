import { ApplicationRef, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { EColorEstado, ETipoAlerta, ETraduccionEstadoPerfil } from 'src/app/models/enums.enum';
import { IPeticionAdmin, IRespuestaBuscarEmpresa, IRespuestaListaUsuario } from 'src/app/models/interfaces-apis/administracion';
import { IDiccionarios, IItem } from 'src/app/models/interfaces-apis/diccionarios';
import { IFichaCliente, IRespuestaFichaCliente } from 'src/app/models/interfaces-apis/ficha_cliente';
import { AdminService } from 'src/app/servicios/admin.service';
import { FormularioUsuarioComponent } from '../../vista-ficha-usuario/formulario/formulario-usuario/formulario-usuario.component';
import { IExpresion } from 'src/app/models/General/expresion-regular';
import { AlertaService } from 'src/app/shared/alerta.service';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-tabla-usuarios',
  templateUrl: './tabla-usuarios.component.html',
  styleUrls: ['./tabla-usuarios.component.scss']
})
export class TablaUsuariosComponent implements OnInit {

  @ViewChild('formulario', { read: ViewContainerRef }) viewContainerRef: ViewContainerRef;
  instanciaFormulario: any = null


  @Input() usuarios: IRespuestaListaUsuario[] = [];
  @Input() diccionarios: IDiccionarios[] = []
  @Input() empresa: IRespuestaBuscarEmpresa = null;

  @Input() colorEstado: any;
  @Input() estadosFiltrados: any[] = [];
  @Input() nombreEstado: any
  lectura: boolean = true

  @Input() listaPaises: IItem[] = [];
  @Input() expresionesRegulares: IExpresion;
  @Input() listaPrefijos: IItem[];
  @Input() listaComunidades: IItem[];
  

  peticion: IPeticionAdmin = {
    Cliente: null
  }


  @ViewChild('modal') private modal: SwalComponent;

  clienteFicha: IFichaCliente = null;


  constructor(private _adminService: AdminService,  private _alertaService: AlertaService, private _translate: TranslateService) { }

  ngOnInit() {
    
  }

  //Nos devuelve los estados del perfil del cliente
  infoEstado(id: number) {
    return ETraduccionEstadoPerfil[id];
  }

  //Carga de Cliente para poder ver la informacion dentro del modal
  cargaCliente(cliente: IRespuestaListaUsuario) {
    this.instanciaFormulario = null
    if (this.instanciaFormulario == null) {
      this._alertaService.alertaToasts(this._translate.instant('precliente.formulario.alerta-cargando-datos'), ETipoAlerta.Info)
      this.insertElement()
    }

    this.peticion.Cliente = cliente.Id
    this._adminService.datosCliente(this.peticion).subscribe({
      next: (data: any) => {
        this.clienteFicha = data;
        this.instanciaFormulario.instance.clienteFicha = this.clienteFicha
        this.instanciaFormulario.instance.actualizar();
      }
    });
    
  }

  insertElement(): void {
    this.viewContainerRef.clear();
    this.instanciaFormulario = this.viewContainerRef.createComponent(FormularioUsuarioComponent)
    this.instanciaFormulario.instance.diccionarios = this.diccionarios
    this.instanciaFormulario.instance.lectura = this.lectura
    this.instanciaFormulario.instance.listaPaises = this.listaPaises
    this.instanciaFormulario.instance.expresionesRegulares = this.expresionesRegulares
    this.instanciaFormulario.instance.listaPrefijos = this.listaPrefijos
    this.instanciaFormulario.instance.listaComunidades = this.listaComunidades
  }

 
}
