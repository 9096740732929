import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-input-group',
  templateUrl: './input-group.component.html',
  styleUrls: ['./input-group.component.scss']
})


/**
 * Componente que permite generar label
 */
export class InputGroupComponent
{
  //#region INPUTS

  /**
   * Indica la id que tendra el input
   */
  @Input() id: string;

  /**
   * Control del formulario
   */
  @Input() control: FormControl;

  /**
   * Indica el tipo de input a utilizar
   */
  @Input() type: string = 'text';

  /**
   * Indica el nombre que tendra el input
   */
  @Input() nombre: string;

  /**
   * Indica la clase que tendra el input
   */
  @Input() clase: string;

  /**
   * Indica la clase que tendra el span
   */
  @Input() claseSpan: string;

  /**
   * Indica el nombre que tendra el icono
   */
  @Input() nombreIcono: string;

  /**
   * Icono que se mostrara para el label
   */
  @Input() icono: string;

  /**
   * Indica el placeholder del input
   */
  @Input() placeholder: string;

  /**
   * Indica si el input es requerido o no
   */
  @Input() isRequired: boolean = false;

  //#endregion


  /**
   * Evento de cambio del input
   */
  @Output() controlValueChange = new EventEmitter<string>();

  /**
   * Método que permite actualizar el valor del formulario
   * @param newValue Nuevo valor del input
   */
  onInputChange(event: any) {
    const newValue = event.target.value;
    this.control.setValue(newValue);
    this.controlValueChange.emit(newValue);
  }

}
