import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { preferencias } from 'src/app/models/General/preferencias';
import { ObservacionesComponent } from '../observaciones/observaciones.component';
import { CheckboxPrimeNGComponent } from '../primeNG-framework/checkbox-primeNG/checkbox-primeNG.component';


@Component({
  selector: 'app-grupo-preferencia',
  templateUrl: './grupo-preferencia.component.html',
  styleUrls: ['./grupo-preferencia.component.scss']
})
export class GrupoPreferenciaComponent extends preferencias implements OnInit {

  @ViewChild(CheckboxPrimeNGComponent) checkboxComponent: CheckboxPrimeNGComponent;
  @ViewChild(ObservacionesComponent) observacionComponent: ObservacionesComponent;

  @Input() TituloObservacion: string = '';
  @Input() TituloGrupo: string = '';
  @Input() ListaPreferencias: string[] = [];
  @Input() RequiereObservacion: boolean = false;
  @Input() ClaseAdicional: string = '';
  @Input() multiple: boolean = true;
  @Input() required: boolean = false;


  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  otro = '9'
  opcionesSeleccionadas: string[] = [];
  isChecked9: boolean = false;

   /**
   * Incluimos la opcion Otro a nuestras opciones seleccionadas
   */
  ListaOpcionesMarcadas(opcionesSeleccionadas: string[]) {
    this.isChecked9 = opcionesSeleccionadas.includes(this.otro);
    this.opcionesSeleccionadas = opcionesSeleccionadas;
  }

   /**
   * Obtenemos las preferencias, tanto las opciones marcadas como la observación
   */
  obtenerPreferencias(): [opcionesSeleccionadas: string[], texto: string] {
    let Observacion = null
    if (this.texto !== null && this.texto.length > 0) {
      Observacion = this.texto;
    }
    return [this.opcionesSeleccionadas, Observacion];
  }

   /**
   * Actualizar tanto las opciones como las observaciones
   */
  actualizar(opcionesSeleccionadas: string[], observacion: string = null) {
    this.isChecked9 = opcionesSeleccionadas?.includes(this.otro);
    this.opcionesSeleccionadas = opcionesSeleccionadas;

    this.checkboxComponent.actualizar(this.opcionesSeleccionadas)

    if (observacion !== null) {
      this.observacionComponent.actualizar(observacion)
    }
  }

   /**
   * Validar preferencias seleccionadas
   */
  validarPreferencias() {
    if (this.required && this.opcionesSeleccionadas.length == 0) {
      return false
    }
    return true
  }



}
