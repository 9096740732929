import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { EMensaje, ERuta, ETipoAlerta } from 'src/app/models/enums.enum';
import { Util } from './util';

@Injectable({
  providedIn: 'root'
})

/**
 * Clase servicio que permite encapsular el uso de las alertas de la aplicación
 */
export class AlertaService {

  /**
   * Lista de iconos a mostrar en las alertas
   */
  private iconos: { [key in ETipoAlerta]: any } =
    {
      [ETipoAlerta.Success]: 'success',
      [ETipoAlerta.Error]: 'error',
      [ETipoAlerta.Warning]: 'warning',
      [ETipoAlerta.Info]: 'info',
      [ETipoAlerta.Question]: 'question'
    };

  /**
   * Constructor del servicio
   * @param _translate: Servicio de traducciones
   */
  constructor(private _translate: TranslateService) {
   }

  colorAzul = '#3085d6';
  colorRojo = '#d33';

  /**
   * Método que permite mostrar una alerta
   * @param mensaje Mensaje que se desea mostrar en la alerta
   * @param tipo Tipo de alerta que se desea abrir
   */
  mostrarAlerta(mensaje: EMensaje, tipo: ETipoAlerta, ruta: ERuta = null ) {
    Swal.fire({
      text: this._translate.instant(mensaje),
      icon: this.iconos[tipo],
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.isConfirmed && ruta != null) {
        Util.redirigir(ruta);
      }
    });
  }


/**
 * Método que permite mostrar una alerta con html
 * @param titulo Titulo que se desea mostrar en la alerta
 * @param mensaje Mensaje que se desea mostrar en la alerta
 * @param tipo Tipo de alerta que se desea abrir
 */
mostrarAlertaConTitulo(titulo: string, mensaje: string, tipo: ETipoAlerta,  ruta: ERuta = null) {
  Swal.fire({
    icon: this.iconos[tipo],
    title: this._translate.instant(titulo),
    text: mensaje
  });
}

/**
 * Método que permite mostrar una alerta con html
 * @param titulo Titulo que se desea mostrar en la alerta
 * @param html HTML que se desea mostrar en la alerta
 * @param tipo Tipo de alerta que se desea abrir
 */
mostrarAlertaHtml(titulo: string, html: string, tipo: ETipoAlerta) {
  Swal.fire({
    icon: this.iconos[tipo],
    title: this._translate.instant(titulo),
    html: html
  });
}
//Pemite mostrar una alerta personalizada
mostrarAlertaPersonalizada(titulo: string, html: string, tipo: ETipoAlerta, botonPrincipal: string | null, accionPrincipal: Function , colorPrincipal: string , botonSecundario: string | null = null, accionSecundaria: Function | null = null, colorSecundario: string = this.colorRojo ) {
  Swal.fire({
    icon: this.iconos[tipo],
    title: this._translate.instant(titulo),
    html: html,
    showDenyButton: botonSecundario !== null,
    confirmButtonColor: colorPrincipal,
    denyButtonColor: colorSecundario,
    allowOutsideClick: false,
    allowEscapeKey: false,
    confirmButtonText: botonPrincipal,
    denyButtonText: botonSecundario,
    showConfirmButton: botonPrincipal !== null,
    reverseButtons: true

  }).then((result) => {
    
    if (result.isDenied) {
      if(accionSecundaria !== null){
        accionSecundaria()
      }
    };
    
    if (result.isConfirmed) {
      accionPrincipal()
    };
  })
}
  

/**
 * Método que permite desplegar una alerta para indicar el cierre de sesion
 * @param nombre
 */
alertaCerrarSesion(nombre: string, accion: Function)
{
  let titulo = this._translate.instant(EMensaje.Atencion)
  let html =  "<div class=g--margin-t-15><b>" + nombre + "</b></div><div class=g--margin-t-15>" + this._translate.instant('precliente.alert.cerrar-sesion') +"</div>"
  let botonVolver = '<i class="pi pi-chevron-left g--margin-r-10"></i>' + this._translate.instant(EMensaje.BotonVolver);
  let botonConfirmar = '<i class="pi pi-sign-out g--margin-r-10"></i>' + this._translate.instant(EMensaje.BotonConfirmar);
  this.mostrarAlertaPersonalizada(titulo, html, ETipoAlerta.Warning, botonConfirmar, accion, this.colorAzul, botonVolver);
 
}


/**
 * Método que permite desplegar una alerta para indicar que finaliza el formulario
 */
finalizarFormulario(nombre: string, accion: Function) {
  let titulo = this._translate.instant(EMensaje.TituloFinalizarYCerrarSesion)
  let html =  "<div><div class=g--margin-30><b>" + nombre + "</b></div>" +
  "<div style='margin:30px 10px 10px;background-color:#fff8cc;' class='g--margin-t-15 c-table-alert'><div>"+ this._translate.instant('precliente.alert.finalizar-formulario') + '<br>' + this._translate.instant('precliente.alert.finalizar-cambios') + '</div>'
  let botonVolver = this._translate.instant('precliente.boton.cumplimentar-datos')
  let botonConfirmar = '<i class="pi pi-sign-out g--margin-r-10"></i>' + this._translate.instant(EMensaje.BotonFinalizarFormulario);
  this.mostrarAlertaPersonalizada(titulo, html, ETipoAlerta.Question, botonConfirmar, accion, this.colorAzul, botonVolver);
  
};

/**
 * Método que permite desplegar una alerta para finalizar y salir
 */
finalizarSalir(nombre: string, accion: Function) {
  let titulo = this._translate.instant(EMensaje.TituloEnhorabuena)
  let html =  "<div><div class=g--margin-30><b>" + nombre  + "</b></div><div class=g--margin-t-15> " + this._translate.instant('precliente.alert.mensaje-finalizar') + '</div>'
  "<div style='margin:30px 10px 10px;background-color:#fff8cc;' class='g--margin-t-15 c-table'> " + this._translate.instant('precliente.alert.cumplimiento-preferencias') + '</div></div>'
  let botonConfirmar = '<i class="pi pi-sign-out g--margin-10"></i>' + this._translate.instant(EMensaje.BotonTerminar);
  this.mostrarAlertaPersonalizada(titulo, html, ETipoAlerta.Success, botonConfirmar, accion, this.colorAzul);
 
};

/**
 * Método que permite desplegar una alerta para eliminar un Documento
 */
eliminarDocumento(accion: Function){
  let titulo = this._translate.instant('perfil.lista-documentos.atencion')
  let html = this._translate.instant('perfil.lista-documentos.borrar-documento')
  let botonVolver = this._translate.instant('perfil.lista-documentos.cancelar')
  let botonConfirmar = this._translate.instant('perfil.lista-documentos.borrar')
  this.mostrarAlertaPersonalizada(titulo, html, ETipoAlerta.Question, botonConfirmar, accion, this.colorAzul, botonVolver)
  
}

/**
 * Método que permite desplegar una alerta para eliminar un Tarjeta de fidelización
 */
eliminarTarjetaFidelizacion(accion: Function){
  let titulo = this._translate.instant('perfil.lista-documentos.atencion')
  let html = this._translate.instant('perfil.lista-documentos.eliminar-tarjeta') 
  let botonVolver = this._translate.instant('perfil.lista-documentos.cancelar')
  let botonConfirmar = this._translate.instant('precliente.formulario.label.borrar')
  this.mostrarAlertaPersonalizada(titulo, html, ETipoAlerta.Question, botonConfirmar, accion, this.colorAzul, botonVolver)
}

/**
 * Método que permite desplegar una alerta para cuando se va a realizar una carga de precliente en la base de datos
 */
cargarBbddPrecliente(accion: Function, texto: string){
  let titulo = this._translate.instant('precliente.alert.atencion')
  texto = '<strong> ' + texto + '</strong>'
  let html =  '<div class="l-flex l-flex--direction-column l-flex__aling-items--center"><div>' + this._translate.instant('precliente.alert.cargarBbdd', {'valor' : texto }) + '</div>'
  let botonVolver = this._translate.instant('admin.analytics.cancelar')
  let botonConfirmar = this._translate.instant('precliente.boton.cargarprecliente')
  this.mostrarAlertaPersonalizada(titulo, html, ETipoAlerta.Question, botonConfirmar, accion, this.colorAzul, botonVolver)
}

/**
 * Método que permite hacer una sincronización y enviar a Destinux
 */
sincronizar(accion: Function){
  let titulo = this._translate.instant('precliente.alert.atencion')
  let html = '<div class="l-flex l-flex--direction-column l-flex__aling-items--center"><div>' + this._translate.instant('precliente.alert.pregunta-sincronizacion') + '<br>' + this._translate.instant('precliente.alert.nota-sincronizacion') + '</div>'
  let botonVolver = this._translate.instant('admin.analytics.cancelar')
  let botonConfirmar = this._translate.instant('precliente.alert.sincronizar')
  this.mostrarAlertaPersonalizada(titulo, html, ETipoAlerta.Question, botonConfirmar, accion, this.colorAzul, botonVolver)
}

/**
 * Método para confirmar que no aceptas las condiciones y politicas
 */
politicasYCondiciones(accion: Function) {
  let titulo = this._translate.instant('precliente.alert.titulo.politicas-condiciones')
  let html = "<div style='margin:30px 10px 10px;background-color:#fff8cc;' class='g--margin-t-15 c-table-alert'><div>"+ this._translate.instant('precliente.alert.texto.politicas-condiciones')
  let botonVolver = this._translate.instant('precliente.boton.cumplimentar-datos')
  let botonConfirmar =  this._translate.instant('precliente.boton.volver-login');
  this.mostrarAlertaPersonalizada(titulo, html, ETipoAlerta.Question, botonConfirmar, accion, this.colorRojo, botonVolver, null, this.colorAzul);
  
};

/**
 * Método que permite desplegar una alerta emergente y breve para notificar al usuario que ha sido correcto
 */
alertaToasts(titulo: string, tipo: ETipoAlerta = ETipoAlerta.Success){
  Swal.fire({
    toast: true,
    icon: this.iconos[tipo],
    title: titulo,
    animation: false,
    position: 'top-right',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: false,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });
}

/**
 * Método que permite desplegar una alerta emergente y breve para notificar al usuario que estan cargando los datos nada mas entrar
 */
mostrarCargando(titulo: string, text:string) {
  Swal.fire({
    title: titulo,
    text: text, 
    icon: 'info',
    timer: 3000,
    allowOutsideClick: false,
    showConfirmButton: false,
    backdrop: true, 
    customClass: {
      popup: 'swal-modal-cargando', 
      icon: 'swal-icon-cargando'
    },
    didOpen: () => {
      Swal.showLoading();
    }
  });
}

}