import { Pipe, PipeTransform } from '@angular/core';
import { Util } from './util';
import { IItem } from '../models/interfaces-apis/diccionarios';

@Pipe({
  name: 'filtro'
})

/**
 * Clase que permite realizar el filtro de las opciones de selector
 */
export class FiltroSelectPipe implements PipeTransform
{

  /**
   * Método que permite realizar la busqueda de un texto en la lista de opciones
   * @param opciones Lista de opciones
   * @param textoBusqueda Texto a buscar en las opciones
   * @returns Lista filtrada
   */
  transform(opciones: IItem[], textoBusqueda: string): IItem[] {
    if(!opciones || !opciones.length) return opciones;
    if(!textoBusqueda || !textoBusqueda.length) return opciones;
    let resultados = opciones.filter(item =>
      item.Valor.toLowerCase().includes(textoBusqueda.toLowerCase())
    );

    if(resultados.length > 0)
    {
      if(resultados.length > 10){
         resultados = opciones.filter(item =>
          item.Valor.toLowerCase().startsWith(textoBusqueda.toLowerCase())
        );
      }
      return resultados;
    }

    return Util.itemDefecto();
  }

}
