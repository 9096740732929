import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { EMensaje, ERol, ERuta, ETipoAlerta } from 'src/app/models/enums.enum';
import { UserService } from 'src/app/servicios/user.service';
import { AlertaService } from 'src/app/shared/alerta.service';
import { Util } from 'src/app/shared/util';

@Injectable({
  providedIn: 'root'
})
export class GuardAdminGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  
  //Guard para no poder acceder a administrador en caso de no tener el rol correcto
  constructor(private _userService: UserService, private _alertaService: AlertaService) {
  let token = localStorage.getItem('user_token'); //TODO Establecer token en user_token
  let rol = parseInt(localStorage.getItem('rol'));
  if (!Util.validarGUID(token) || rol != ERol.Admin) {
    this._alertaService.mostrarAlerta(EMensaje.RolNoPermitido, ETipoAlerta.Error, ERuta.LoginAdmin)

  }
}


}
