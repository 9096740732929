import { AbstractControl, FormControl } from "@angular/forms";
import { ETipoDocumento } from "../models/enums.enum";
import { FORMATO_FECHA, PAIS_ESPANA } from "../models/constantes";
import * as dayjs from "dayjs";


export class Validaciones{

  //Valdiar formato NIE correcto
  static validarNIE(numeroID): boolean {
    return /^[xyzXYZ][0-9]{7}[a-zA-z]$/.test(numeroID);
  }
  //Valdiar formato DNI correcto
  static validarDNI(numeroID): boolean {
    return /^[0-9]{8}[a-zA-z]$/.test(numeroID);
  }
}

/**
  * Función para validar la fecha de Caducidad
  */
export function validarFechaCaducidad(control: AbstractControl) : {[key: string]: any} | null {
    const fecha = control.value;
    if (fecha) {
      const fechaActual = new Date();
      const fechaIngresada = new Date(fecha);
      if (fechaIngresada < fechaActual) {
        return { 'fechaInvalida': true }
      }
  
    }
    return null;

  }

  /**
  * Función para validar que la fecha de Expedicion
  */
export  function validarFechaExpedicion(control: AbstractControl) : {[key: string]: any} | null {
    const fecha = control.value;
    if (fecha) {
      const fechaActual = new Date();
      const fechaIngresada = new Date(fecha);
  
      if (fechaIngresada > fechaActual) {
        return { 'fechaInvalida': true }
      }
  
    }
    return null;
  }

  /**
  * Función para validar que un usuario es mayor de edad
  */
  export  function validarFechaMayor18(control: AbstractControl) : {[key: string]: any} | null {
    const fecha = control.value;
    if (fecha) {
      const fechaIngresada = dayjs(fecha);
      
      const fechaActual = dayjs()
      const edad = fechaActual.diff(fechaIngresada, 'year');
      
      if (edad  < 18) {
        return { 'fechaMenorEdad': true }
      }
  
    }
    return null;
  }
  


  
 