<div class="container-fluid color-fondo scaled-content g--padding-r-l-0">
  <div class="row margin-0-small">

    <!-- Contenedor Barra Lateral -->
    <div class="col-12 col-md-2 p-0">
      <app-seccion-perfil-ficha [nombre]="nombrePerfil" [ramasEmpresa]="ramasPerfil"></app-seccion-perfil-ficha>
    </div>


    <!-- Contenedor Ficha Cliente -->
    <div class="col-12 col-md-10 g--padding-r-l-0" [hidden]="clienteFicha.FechaAceptacionPoliticas == null">
      <app-ficha-usuario
       [diccionarios] = "diccionarios"
       [clienteFicha] = "clienteFicha"
       [listaPaises] = "listaPaises"
       [listaPrefijos] = "listaPrefijos"
       [expresionesRegulares] = "expresionesRegulares"
       [listaComunidades] = "listaComunidades"

       >
      </app-ficha-usuario>
    </div>

    
    
      <div class="col-12 col-md-10 g--padding-r-l-0" *ngIf="clienteFicha.Correo !== '' && clienteFicha.FechaAceptacionPoliticas == null">
        <app-politicas-condiciones
          
          [clienteFicha] = "clienteFicha"
          [lectura] = "lectura"
        ></app-politicas-condiciones>
      </div>

  </div>
</div>

<app-footer></app-footer>