import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-input-material',
  templateUrl: './input-material.component.html',
  styleUrls: ['./input-material.component.scss']
})

/**
 * Componente que permite generar input de material
 */
export class InputMaterialComponent
{

  //#region INPUTS

  /**
   * Indica la id que tendra el input
   */
  @Input() id: string;

  /**
   * Control del formulario
   */
  @Input() control: FormControl;

  /**
   * Indica el tipo de input a utilizar
   */
  @Input() type: string = 'text';

  /**
   * Indica el nombre que tendra el input
   */
  @Input() nombre: string;

  /**
   * Indica el nombre que tendra el label
   */
  @Input() label: string;

  /**
   * Indica la clase que tendra el input
   */
  @Input() clase: string = 'form-control';

  /**
   * Indica la clase que tendra el icono
   */
  @Input() claseIcono: string;

  /**
   * Indica el placeholder del input
   */
  @Input() placeholder: string;

  /**
   * Indica si el input es requerido o no
   */
  @Input() isRequired: boolean = false;

   /**
   * Indica campo lectura que tendra el input
   */
   @Input() readonly: boolean;

   /**
   * Clase para el ancho que tendra el input
   */
   @Input() widthClass: string = '';

  //#endregion

}
