import { Component, Input } from '@angular/core';
import { ERuta } from 'src/app/models/enums.enum';
import { AlertaService } from 'src/app/shared/alerta.service';
import { Util } from 'src/app/shared/util';

@Component({
  selector: 'app-seccion-perfil-ficha',
  templateUrl: './seccion-perfil-ficha.component.html',
  styleUrls: ['./seccion-perfil-ficha.component.scss']
})

/**
 * Componente seccion perfil que permite mostrar la informacion basica del usuario
 */
export class SeccionPerfilFichaComponent {

  //#region INPUTS

  /**
   * Indica el nombre del usuario
   */
  @Input() nombre: string;

  /**
   * Indica las ramas de la empresa del usuario
   */
  @Input() ramasEmpresa: any[] = [];

  //#endregion

  /**
   * Constructor del componente
   * @param _alertaService: Servicio de alerta
   */
  constructor(private _alertaService: AlertaService) { }

  //#region METODO PUBLICOS

  /**
   * Método que permite realizar el cierre de sesion
   */
  cerrarSesion() {
    this._alertaService.alertaCerrarSesion(this.nombre, this.rederigir);
  }

  //Rederigir al Login
  rederigir() {
    Util.redirigir(ERuta.Login)
  }

  //#endregion

}
