<div class="container-fluid g--height-100-min100vh p-3 text-white  fondo-seccion-perfil">
  <div class="content">

    <!-- Imagen Destinux -->
    <app-imagen-destinux></app-imagen-destinux>
      
    <!-- Datos usuario -->
    <div class="row custom-row d-flex">
      <div class="col-6 custom-col mb-3 mb-md-0 ">

        <div class="c-text c-text--md  g--padding-12 g--redondeado color-nombre-barra__lateral icon-text-container">
          <div class="col-auto" >
            <i class="fa-solid fa-circle-user fa-2xl p-1"></i>
          </div>  
          <div class="col ajuste-palabras">
            {{nombre}}
          </div>
        </div>
          
      </div>
    
        <!-- Boton cerrar sesion -->
        <div class="col-6 custom-col text-center c-text c-text--md justify-content-center margin--screen__big">
          <app-boton
            clase="c-button c-button--transparent c-text p-1"
            [nombre]="'modal-sigue-activo.cerrar-sesion'"
            [usaIcono]="true"
            claseIcono="pi pi-sign-out p-1 me-1"
            (click)="cerrarSesion()"
          ></app-boton>
        </div>

    </div>
  </div>

    <!-- Menu opara pantallas pequeñas -->
    <div class="content-menu">
      <mat-toolbar class="responsive-toolbar">
        <mat-toolbar-row>
          <div class="contenido-imagen">
            <app-imagen-destinux></app-imagen-destinux>
          </div>
          <div class="contenido-menu">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <i class="fa-solid fa-circle-user fa-2xl p-1"></i>
            </button>
          </div>
        </mat-toolbar-row>
      </mat-toolbar>
    </div>
    
    <mat-menu #menu="matMenu">
      <button class="c-text c-text--md" mat-menu-item> {{nombre}}</button>
      <button class="c-text c-text--md" mat-menu-item> <app-boton
        clase="c-button  c-text p-1"
        [nombre]="'modal-sigue-activo.cerrar-sesion'"
        [usaIcono]="true"
        claseIcono="pi pi-sign-out p-1 me-1"
        (click)="cerrarSesion()"
      ></app-boton></button>
    </mat-menu>

      <!-- Datos empresa -->
      <div class="row my-3 c-text c-text--md g--margin-r-15">
        <div class="col-12 g--margin-t-15">
          <span>{{'perfil.empresas-asociadas'|translate}}:</span>
        </div>
      
        <div class="col-12 my-2">
          <ul class="mt-1">
            <div *ngFor="let rama of ramasEmpresa" class="my-2">
              <li>{{rama.Sociedad.Nombre}}</li>
            </div>
          </ul>
        </div>
      </div>

</div>


