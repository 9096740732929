<mat-form-field class="w-100" appearance="outline" class="custom-outline" [ngClass]="widthClass">

  <mat-label class="c-text c-text--gd">{{label}}</mat-label>

  <input matInput class="c-input__calendar" (click)="nombre.open()"
    [id]="id"
    [max]="max"
    [min]="min"
    [required]="isRequired"
    
    [matDatepicker]="nombre"
    [formControl]="control"
    (dateChange)="formatearFecha($event)"
    [readonly]="readonly"
  >

  <!-- Icono de calendario -->
   <mat-datepicker-toggle *ngIf="mostrarIconoCalendario" matPrefix [for]="nombre"
      class="g--color-principal-11 g--padding-t-10"></mat-datepicker-toggle>

  <mat-datepicker matPrefix #nombre  [disabled]="disabled"></mat-datepicker>
  <button mat-icon-button matSuffix  class="close" *ngIf="!lectura && mostrarBoton" lectura="lectura" (click)="vaciar()">
    <mat-icon >close</mat-icon>
  </button>

</mat-form-field>
