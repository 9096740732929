<div class="container fluid g--padding-r-l-0">
  <mat-horizontal-stepper #stepper labelPosition="bottom" linear class="mx-3">

    <!-- 1º Mat-step Ficha de Usuario -->
    <mat-step  [stepControl]="">
      <ng-template matStepLabel>
        <label class="mat-step-label c-text c-text--md">{{'precliente.formulario.step.fichausuario'|translate}}</label>
      </ng-template>
      <app-formulario-usuario [diccionarios] = "diccionarios" [clienteFicha] = "clienteFicha"  [listaPaises] = "listaPaises"
        [listaPrefijos] = "listaPrefijos" [listaComunidades]="listaComunidades"
        [expresionesRegulares] = "expresionesRegulares"
        (formularioChanges)="validarFormularioFicha($event)" 
      ></app-formulario-usuario>
    </mat-step>

    <!-- 2º Mat-step Perfil -->
    <mat-step [stepControl]=""  >
      <ng-template matStepLabel>
        <label class="mat-step-label c-text c-text--md">{{'precliente.formulario.step.perfil' | translate}}</label>
      </ng-template>
      <app-usuario-perfil [preferencias] = "diccionarios['DiccionariosPreferencias']"
        [clienteFicha] = "clienteFicha"
      ></app-usuario-perfil>
    </mat-step>

    <!-- 3º Mat-step Preferencias -->
    <mat-step [stepControl]="">
      <ng-template matStepLabel>
        <label class="mat-step-label  c-text c-text--md">{{'precliente.formulario.step.preferencias' | translate}}</label>
      </ng-template>
      <app-usuario-preferencias [preferencias] = "diccionarios['DiccionariosPreferencias']"
        [clienteFicha] = "clienteFicha"
        [AereoLineas] = "diccionarios['AereoLineas']"
        [Companias] = "diccionarios['Companias']"
        [CategoriasCoche] = "diccionarios['CategoriaVehiculo']"
      ></app-usuario-preferencias>
    </mat-step>
    
  </mat-horizontal-stepper>

  <div class="l-fotter">

    <!-- Boton de Volver -->
    <div class="g--width-100-on" *ngIf = "this.stepper.selectedIndex > 0" >
      <app-boton   (click)="clicarVolver()" [nombre]="'auto-reserva.hoteles.prereserva.volver'"
        clase="c-button c-button--primary c-text c-text--md g--padding-12 g--padding-t-10  g--width-100">
      </app-boton>
    </div>

    <!-- Boton de Guardar -->
    <div class=" g--width-100-on">
      <app-boton (click)="recopilarDatos()"  [nombre]="'perfil.editar-perfil.guardar'"
        clase="c-button c-button--primary c-text c-text--md g--padding-12 g--padding-t-10  g--width-100">
      </app-boton>
    </div>

    <!-- Boton de Guardar y seguir -->
    <div class="  g--width-100-on" *ngIf = "this.stepper.selectedIndex !== 2">
      <app-boton (click)="recopilarDatos(EAccion.Continuar)" [nombre]="'precliente.boton.guardar-y-seguir'"
        clase="c-button c-button--primary c-text c-text--md g--padding-12 g--padding-t-10  g--width-100">
      </app-boton>
    </div>

    <!-- Boton de Finalizar -->
    <div class="  g--width-100-on" *ngIf = "this.stepper.selectedIndex == 2">
      <app-boton (click)="recopilarDatos(EAccion.Finalizar)" [deshabilitado] = "GuardarCambiosDeshabilitado == false" [nombre]="'precliente.boton.finalizar'"
          clase="c-button c-button--primary c-text c-text--md g--padding-12 g--padding-t-10  g--width-100">
      </app-boton>
    </div>

  </div>
</div>