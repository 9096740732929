
<div class="row no-margin justify-content-between  seccion-documentos-tarjeta" >
  <div class="col-7 g--padding-t-16 text-left align-self-center c-titulo c-titulo--md"  (click)="actualizarTabla()" #updateButton>
    {{"perfil.lista-documentos.documentos-y-bonificaciones" | translate}}
  </div>
  
  <div class="col-3 text-center">
    <!-- Boton crear documento -->
    <app-boton
      clase="c-button c-button--primary c-text c-text--md g--padding-12"
      [nombre]="'perfil.editar-perfil.anyadir'"
      (click)="crearDocumento()"
      [lectura]="lectura"
      
    ></app-boton>

  </div>
  <div class="c-text c-text--md color-text-informacion g--padding-t-10">{{'precliente.formulario.uso-descuento' | translate }}</div>
</div>

<div class="container">
  <form [formGroup]="form" class="form-container">
    <div class="row">
      <mat-table
        #table
        class="mat-elevation-z0"
        [dataSource]="dataSource">
        

        <!-- Form Column -->
    
        <ng-container matColumnDef="TipoDocumento">
          <mat-cell *matCellDef="let row" class="TipoDocumento-cell">
            <div class="col-12 col-md-10 col-lg-10">
              <ng-container [formGroup]="row">
                <!-- Select para todos los tipos de documentos -->
                <app-select-search-material
                [buscador]="false"
                [multiple]="false"
                [opciones]="TiposDocumentos"
                id="TipoDocumento"
                clase="form-control form-control-md"
                claseIcono=""
                label="{{'perfil.lista-documentos.tipo-documento' | translate }}"
                placeholderBuscador="{{'perfil.lista-documentos.tipo-documento' | translate }}"
                placeholderSelector="{{'perfil.lista-documentos.tipo-documento' | translate }}"
                [control]="row.get('TipoDocumento')"
                (opcionSeleccionadaEvent)="validarDocumentos($event, 0, row)"
                [readonly]="lectura"
                [isRequired]="true"
                >
                </app-select-search-material>
                <mat-error *ngIf="row.get('TipoDocumento').hasError('required')">
                  {{'precliente.formulario.validaciones.campo-obligatorio' | translate }}
                </mat-error>
              </ng-container>
          
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="PaisNacionalidad">
          <mat-cell *matCellDef="let row" [ngClass]="{'oculto': !(row.get('TipoDocumento').value == ETipoDocumento.NumIdentificacion &&
            row.get('PaisExpedicion').value == PAIS_ESPANA && validarNIE(row.get('NumDocumento').value))}" class="PaisNacionalidad-cell">
            <div class="col-12 col-md-10 col-lg-10">
              
              <ng-container [formGroup]="row">
                <div *ngIf="row.get('TipoDocumento').value == ETipoDocumento.NumIdentificacion &&
                row.get('PaisExpedicion').value == PAIS_ESPANA && validarNIE(row.get('NumDocumento').value)">
                <!-- Select para País de nacionalidad cuando sea TipoDocumento=DNI PaisExpedicion=España NumeroDocumento=NIE -->
                  <app-select-search-material
                    [buscador]="true"
                    [multiple]="false"
                    [opciones]="listaPaises"
                    id="PaisExpedicion"
                    clase="form-control form-control-md"
                    claseIcono=""
                    label="{{'perfil.editar-perfil.nacionalidad' | translate }}"
                    placeholderBuscador="{{'perfil.editar-perfil.nacionalidad' | translate }}"
                    placeholderSelector="{{'perfil.editar-perfil.nacionalidad' | translate }}"
                    [control]="row.get('PaisNacionalidad')"
                    (opcionSeleccionadaEvent)="validarNieNacionaldiad(row)"
                    [readonly]="lectura"
                    [isRequired]="true"
                  >
                  </app-select-search-material>
                  <mat-error *ngIf="row.get('PaisNacionalidad').hasError('required')">
                    {{'precliente.formulario.validaciones.campo-obligatorio' | translate }}
                  </mat-error>
                </div>
              </ng-container>
          
            </div>
          </mat-cell>
        </ng-container>
      

        <ng-container matColumnDef="InfoFamiliaNumerosaES.CodigoComunidad">
          <mat-cell *matCellDef="let row" [ngClass]="{'oculto': !(row.get('TipoDocumento').value == ETipoDocumento.FamiliaNumerosa
          && row.get('PaisExpedicion').value == PAIS_ESPANA)}" class="InfoFamiliaNumerosaES.CodigoComunidad-cell">
          <div class="col-12 col-md-10 col-lg-10">
              <ng-container [formGroup]="row">
                <div *ngIf="row.get('TipoDocumento').value == ETipoDocumento.FamiliaNumerosa
                  && row.get('PaisExpedicion').value == PAIS_ESPANA">
                  <!-- Select de Comunidades Autónomas cuando el TipoDocumento=FamiliaNumerosa y PaísExpedicion=España -->
                  <app-select-search-material
                    [buscador]="true"
                    [multiple]="false"
                    [opciones]="listaComunidades"
                    id="PaisExpedicion"
                    clase="form-control form-control-md"
                    claseIcono=""
                    label="{{'perfil.lista-documentos.comunidad-autonoma' | translate }}"
                    placeholderBuscador="{{'perfil.lista-documentos.comunidad-autonoma' | translate }}"
                    placeholderSelector="{{'perfil.lista-documentos.comunidad-autonoma' | translate }}"
                    [control]="row.get('InfoFamiliaNumerosaES.CodigoComunidad')"
                    [readonly]="lectura"
                    [isRequired]="true"
                  >
                  </app-select-search-material>
                  <mat-error *ngIf="row.get('InfoFamiliaNumerosaES.CodigoComunidad').hasError('required')">
                    {{'precliente.formulario.validaciones.campo-obligatorio' | translate }}
                  </mat-error>
                </div>
              </ng-container>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="InfoFamiliaNumerosaES.Tipo">
          <mat-cell *matCellDef="let row" [ngClass]="{'oculto': !(row.get('TipoDocumento').value == ETipoDocumento.FamiliaNumerosa
            && row.get('PaisExpedicion').value == PAIS_ESPANA)}" class="InfoFamiliaNumerosaES.Tipo-cell oculto">
            <div class="col-12 col-md-10 col-lg-10">
              <ng-container [formGroup]="row">
                <div *ngIf="row.get('TipoDocumento').value == ETipoDocumento.FamiliaNumerosa
                  && row.get('PaisExpedicion').value == PAIS_ESPANA">
                  <!-- Select de Tipos familia numerosa cuando el TipoDocumento=FamiliaNumerosa y PaísExpedicion=España --> 
                  <app-select-search-material
                    [buscador]="true"
                    [multiple]="false"
                    [opciones]="TiposFamiliaNumerosa"
                    id="PaisExpedicion"
                    clase="form-control form-control-md"
                    claseIcono=""
                    label="{{'perfil.lista-documentos.tipo-familia-numerosa' | translate }}"
                    placeholderBuscador="{{'perfil.lista-documentos.tipo-familia-numerosa' | translate }}"
                    placeholderSelector="{{'perfil.lista-documentos.tipo-familia-numerosa' | translate }}"
                    [control]="row.get('InfoFamiliaNumerosaES.Tipo')"
                    [readonly]="lectura"
                    [isRequired]="true"
                  >
                  </app-select-search-material>
                    
                  <mat-error *ngIf="row.get('InfoFamiliaNumerosaES.Tipo').hasError('required')">
                    {{'precliente.formulario.validaciones.campo-obligatorio' | translate }}
                  </mat-error>
                </div>
              </ng-container>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="PaisExpedicion">
          <mat-cell *matCellDef="let row" class="PaisExpedicion-cell">
            <div class="col-12 col-md-10 col-lg-10">
              <ng-container [formGroup]="row">
                <!-- Select de todos los Paises -->
                <app-select-search-material  
                  [buscador]="true"
                  [multiple]="false"
                  [opciones]="listaPaises"
                  id="PaisExpedicion"
                  clase="form-control form-control-md"
                  claseIcono=""
                  label="{{'perfil.lista-documentos.pais-emision' | translate }}"
                  placeholderBuscador="{{'perfil.lista-documentos.pais-emision' | translate }}"
                  placeholderSelector="{{'perfil.lista-documentos.pais-emision' | translate }}"
                  [control]="row.get('PaisExpedicion')"
                  (opcionSeleccionadaEvent)="validarDocumentos($event,1, row)"
                  [readonly]="lectura"
                  [isRequired]="true"
                >
                </app-select-search-material>
                <mat-error *ngIf="row.get('PaisExpedicion').hasError('required')">
                  {{'precliente.formulario.validaciones.campo-obligatorio' | translate }}
                </mat-error>
              </ng-container>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="NumDocumento">
          <mat-cell *matCellDef="let row" class="NumDocumento-cell">
            <div class="col-12 col-md-10 col-lg-10">
              <ng-container [formGroup]="row">
                <div *ngIf="!(row.get('TipoDocumento').value == ETipoDocumento.Residente && row.get('PaisExpedicion').value == PAIS_ESPANA)">
                  <!-- Campo de número de documento normal -->
                  <app-input-material
                    id="numeroDocumento"
                    class=""
                    type="text"
                    label="{{'perfil.lista-documentos.numero' | translate }}"
                    nombre="numeroDocumento"
                    claseIcono=""
                    [control]="row.get('NumDocumento')"
                    placeholder="{{'perfil.lista-documentos.numero' | translate }}"
                    [isRequired]="true"
                    [readonly]="lectura"
                  ></app-input-material>
                  <mat-error *ngIf="row.get('NumDocumento').hasError('required')">
                    {{'precliente.formulario.validaciones.campo-obligatorio' | translate }}
                  </mat-error>
                  <mat-error *ngIf="row.get('NumDocumento').hasError('pattern')">
                    {{'precliente.formulario.validaciones.caracteres-alfanumericos' | translate }}
                  </mat-error>
                </div>
                
                <div class="campo" *ngIf="row.get('TipoDocumento').value == ETipoDocumento.Residente && row.get('PaisExpedicion').value == PAIS_ESPANA">
                  <!-- Campo de CodigoResidente cuando TipoDocumento=Residente -->
                  <app-select-search-material
                    [buscador]="false"
                    [multiple]="false"
                    [opciones]="convertirResidentes()"
                    id="codigoResidente"
                    clase="form-control form-control-md"
                    claseIcono=""
                    label="{{'perfil.lista-documentos.residente' | translate }}"
                    placeholderBuscador="{{'perfil.lista-documentos.residente' | translate }}"
                    placeholderSelector="{{'perfil.lista-documentos.residente' | translate }}"
                    [control]="row.get('NumDocumento')"
                    [readonly]="lectura"
                  >
                  </app-select-search-material>
                  <mat-error *ngIf="row.get('NumDocumento').hasError('required')">
                    {{'precliente.formulario.validaciones.campo-obligatorio' | translate }}
                  </mat-error>
                </div>
              </ng-container>
            </div>
          </mat-cell>
        </ng-container>
          

        
        <ng-container matColumnDef="FechaExpedicion">
          <mat-cell *matCellDef="let row" class="FechaExpedicion-cell" >
            <div class="col-12 col-12 col-md-10 col-lg-10">
              <ng-container [formGroup]="row">
                <div class="campo">
                  <!-- Input de calendario para la fecha de expedición -->
                  <app-calendar-material
                    id="FechaExpedicion"
                    label="{{'perfil.lista-documentos.fecha-emision' | translate }}"
                    [control]="row.get('FechaExpedicion')"
                    [max]="maximaFechaNacimientoPermitida"
                    [min]="minimaFechaNacimientoPermitida"
                    [isDisabled]="false"
                    [readonly] = "true"
                    [disabled] = "lectura"
                    [mostrarIconoCalendario]="false"
                  >
                  </app-calendar-material>
                  <mat-error *ngIf="row.get('FechaExpedicion').hasError('fechaInvalida')">
                    {{'precliente.formulario.validaciones.fecha-no-mayor-actual' | translate }}
                  </mat-error>
                  <mat-error *ngIf="row.get('FechaExpedicion').hasError('required')">
                    {{'precliente.formulario.validaciones.campo-obligatorio' | translate }}
                  </mat-error>
                </div>
              </ng-container>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="FechaCaducidad">
          <mat-cell *matCellDef="let row" class="FechaCaducidad-cell" >
            <div class="col-12 col-md-10 col-lg-10">
              <ng-container [formGroup]="row">
                <div class="campo">
                  <!-- Input de calendario para la fecha de caducidad -->
                  <app-calendar-material
                    id="FechaCaducidad"
                    label="{{'perfil.lista-documentos.fecha-caducidad' | translate }}"
                    [control]="row.get('FechaCaducidad')"
                    [max]="maximaFechaNacimientoPermitida"
                    [min]="minimaFechaNacimientoPermitida"
                    [isDisabled]="false"
                    [readonly] = "true"
                    [disabled] = "lectura"
                  >
                  </app-calendar-material>
                  <mat-error *ngIf="row.get('FechaCaducidad').hasError('fechaInvalida')">
                    {{'precliente.formulario.validaciones.fecha-no-menor-actual' | translate }}
                  </mat-error>
                  <mat-error *ngIf="row.get('FechaCaducidad').hasError('required')">
                    {{'precliente.formulario.validaciones.campo-obligatorio' | translate }}
                  </mat-error>
              </div>
              </ng-container>
            </div>
          </mat-cell>
        </ng-container>

        
        
        <ng-container matColumnDef="Acciones">
          <mat-cell *matCellDef="let documento; let i = index" class="Acciones-cell cell-small">
            <div class="col-12 col-md-10 col-lg-10">
              <td  class="row">
                <div class="col-12 col-sm-6 g--margin-t-24-small">
                  <button  class="c-button-documentos  button-sm" mat-icon-button (click)="abrirModal(documento)" >
                    <mat-icon>attach_file</mat-icon>
                  </button>
                </div>
              <div #modal class="modal" >
                <h2 class="c-text g--margin-t-10 g--margin-b-15"><b>{{"perfil.lista-documentos.documentos-y-bonificaciones" | translate}}</b></h2>

                <div class="tabla-modal" *ngIf="ficherosExistentes.length > 0">
                  <h3 class="c-text l-flex__text-align--left color-gris g--padding-t-16 g--padding-l-15 g--margin-b-0">{{"precliente.modal-archivos.archivos-cargados" | translate}}</h3>
                  <hr class="linea-horizontal">
                    <table class="no-border-table">      
                        <tr *ngFor="let file of ficherosExistentes">
                          <div *ngIf="ficherosExistentes.length">
                          <td>
                            <a [href]="file.Url" target="_blank">
                              <ng-container *ngIf="ImagenPdf(file.Nombre); else ImagenNormal">
                              <i class="pi pi-file-pdf" style="font-size: 5em; color: red;"></i>
                              </ng-container>
                              <ng-template #ImagenNormal>
                              <img [src]="file.Url" [alt]="file.Nombre">
                              </ng-template>
                            </a>
                          </td>
                          <td class="c-text">{{file.Nombre}}</td>
                          <td><button *ngIf="!lectura" (click)="eliminarArchivo(file)" class="cancelar-button p-button-icon pi pi-times" lectura="lectura"></button></td>
                        </div>
                        </tr>
                      
                    </table>
                </div>
                    
                <div *ngIf="!lectura"  lectura="lectura">
                  <div class="l-flex__text-align--left c-text c-text--md color-text-informacion g--margin-t-24 g--padding-b-10">{{"precliente.modal-archivos.nota" |  translate: {'valor': 5} }}</div>
                    <div class="card flex justify-content-center">
                      <p-fileUpload 
                        #CargarDocumento
                        [customUpload] ="true" 
                        (uploadHandler)="subirArchivo($event)" 
                        (onSelect)="onSelect($event)"
                        [multiple]="true"
                        accept="image/*,.pdf"
                        [maxFileSize]="5000000"
                        [fileLimit]= "30"
                        chooseLabel= "{{'precliente.modal-archivos.boton-elige' | translate}}"
                        uploadLabel="{{'precliente.modal-archivos.boton-subir' | translate}}"
                        cancelLabel="{{'precliente.modal-archivos.boton-cancelar' | translate}}"
                        (onRemove)="eliminarArchivo2($event)"
                      >
                      </p-fileUpload>
                    </div>
                </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" (click)="cerrarModal()">{{"precliente.modal-archivos.boton-cerrar" | translate}}</button>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 g--margin-t-24-small">
                    <button  class="c-button-papelera button-sm"  *ngIf="!lectura" mat-icon-button (click)="cancelarEdicion(i)" lectura="lectura">
                      <mat-icon>delete_outline</mat-icon>
                    </button>
                  </div>
              </td>
            </div>
          </mat-cell>
        </ng-container>
    

        <!-- Row Declarations -->
        <mat-row *matRowDef="let row; columns: columnas" [attr.data-uuid]="row.uuid"  class="sin-borde"></mat-row>

      </mat-table>
    </div>
  </form>
</div>


 