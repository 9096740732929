<div class="c-card g--margin-t-15">
    <div class="c-card__body g--margin-b-30 row">

        <!-- Grupo Preferencias asientos -->
        <div class="g--margin-t-30 col-12 col-lg-4 g--margin-r-30">
            <app-grupo-preferencia [TituloGrupo]="'precliente.formulario.rentacar.imprescindebles'"
                [required]="false"
                #Grupo_Preferencia_Alojamiento_Imprescindibles
                [ListaPreferencias]="obtenerOpcionesPreferencia(preferencias,TipoPreferencia.ImprescindiblesHotel)">
            </app-grupo-preferencia>
        </div>

        <!-- Grupo Alojamiento Cadenas con Observaciones-->
        <div class="g--margin-t-30 col-12 col-lg-4 ">
            <div class="g--margin-t-30 c-section-altnative c-table">
                <div class="c-section-alternative__header">
                    <b>{{"precliente.formulario.hotel.cadenashoteleras" | translate}}</b>
                </div>

                <div class="c-section__body g--margin-t-40">
                    <app-select-search-material
                        #Grupo_Preferencia_Alojamiento_Cadenas
                        [required]="false"
                        [buscador]="true"
                        [multiple]="true"
                        [opciones]="Companias"
                        id="CadenasHoteleras"
                        clase="form-control form-control-md"
                        claseIcono=""
                        label="{{'admin.comun.buscar' | translate }}"
                        placeholderBuscador="{{'admin.comun.buscar' | translate }}"
                        placeholderSelector="{{'admin.comun.buscar' | translate }}"
                        (opcionSeleccionadaEvent)="seleccionarOpcionOtroAlojamiento($event)"
                    >
                    </app-select-search-material>

                    <!-- Observaciones -->
                    <app-observaciones
                        [hidden]="!isOtro"
                        #Grupo_Preferencia_Alojamiento_Cadenas_Observacion
                        [(textareaValue)] = "texto"
                        (textareaValueChange)="actualizarValorObservacion($event)"
                    ></app-observaciones>
                </div>
            </div>
        </div>


    </div>
</div>
