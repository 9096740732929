
import { EnvironmentEnum } from "./environment.enum";

export const environment = {
  generalURL: 'https://apitest-destinux.consultiatravel.es/GP/',
  recursos: 'https://webapp.destinux.com/recursos_online/destinux_test/',
  baseJsonPruebas: 'assets/misc/respuestas_API/',
  entorno: EnvironmentEnum.TEST,
  baseArchivoAssets: 'assets/'
};
