
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EnvironmentEnum } from 'src/environments/environment.enum';


@Injectable({
  providedIn: 'root',
})

/**
 * Clase de servicio base que permite encapsular la logica de las llamadas por medio del cliente http
 */
export class ServiceHttpService
{


  //#region declaración de variables

  #inicioAPI = 'api/';

  //#endregion

  /**
   * Constructor de la clase
   * @param _http Cliente http
   */
  constructor(private _http: HttpClient) {}


  /**
   * Método que permite realizar peticiones GET
   * @param url: Url a la que se desea consultar
   * @param rutaJsonDemo: Ruta del json de prueba a utilizar
   * @param options: Opciones necesaria para invocar la url
   * @returns: Observable con la respuesta de la API
   */
  getApi<T>(url: string, rutaJsonDemo: string | null = null, options: any | null = null): Observable<any>
  {
    if (environment.entorno == EnvironmentEnum.DEMO && rutaJsonDemo !== null)
    {
      return this._http.get<T>(rutaJsonDemo);
    }
    else
    {
      url = this.formatearUrl(url);
      return options === null ? this._http.get<T>(url) : this._http.get<T>(url, options);
    }
  }

  /**
   * Método que permite realizar peticiones POST
   * @param url: Url a la que se desea consultar
   * @param body: Variables enviadas a ser procesadas
   * @param rutaJsonDemo: Ruta del json de prueba a utilizar
   * @param options: Opciones necesaria para invocar la url
   * @returns: Observable con la respuesta de la API
   */
  postApi<T>(url: string, body: any | null, rutaJsonDemo: string | null = null, options: any | null = null): Observable<any>
  {
    if (environment.entorno == EnvironmentEnum.DEMO && rutaJsonDemo !== null)
    {
      return this._http.get<T>(rutaJsonDemo);
    }
    else
    {
      url = this.formatearUrl(url);
      return options === null ? this._http.post<T>(url, body) : this._http.post<T>(url, body, options);
    }
  }

  /**
   * Método que permite definir la ulr a utilizar en base al entorno dado en el environment
   * @param url Url a utilizar
   * @returns Url segun entorno
   */
  formatearUrl(url: string)
  {
    url = this.#inicioAPI + url;
    url = url.replace("//", "/");
    if (environment.entorno == EnvironmentEnum.TEST)
    {
      return "Test/" + url;
    }
 
    return url
  }

}
