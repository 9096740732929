import { Injectable } from '@angular/core';
import { ServiceHttpService } from './service-http.service.';
import { IFichaCliente, IRespuestaFichaCliente } from '../models/interfaces-apis/ficha_cliente';
import { Observable } from 'rxjs';
import { IDiccionarios } from '../models/interfaces-apis/diccionarios';

@Injectable({
  providedIn: 'root'
})

/**
 * Servicio que se encarga de gestionar las consultas de los usuarios
 */
export class UserService
{

  /**
   * Constructor de la clase
   * @param _httpService: Servicio http base
   */
  constructor(private _httpService: ServiceHttpService) { }

  /**
   * Método que permite cargar la información de la ficha del usuario
   * @returns
   */
  cargarCliente(): Observable<IRespuestaFichaCliente>
  {
    return this._httpService.getApi<IRespuestaFichaCliente>('/gestionperfil/Cargar');
  }

  /**
   * Método que permite realizar el guardado del formulario de un usuario
   * @param peticion
   * @returns
   */
  guardarCliente(cliente: IFichaCliente): Observable<any>
  {
    return this._httpService.postApi('/gestionperfil/guardar', cliente);
  }

  /** Método que permite cargar los diccionarios utilizando la ficha del cliente
  */
  cargarDiccionarios(): Observable<IDiccionarios[]>
  {
    return  this._httpService.getApi<IDiccionarios[]>('/gestionperfil/ListaDiccionarios');
  }

  /** Método que permite subir archivos en el apartado de documentos
  */
  subirArchivo(formData: any): Observable<any> {
    return this._httpService.postApi<any>('/gestionperfil/SubirArchivos', formData)
  }

  /** Método que permite eliminar archivos en el apartado de documentos
  */
  eliminarArchivos(formData: any): Observable<any>{
    return this._httpService.postApi<any>('/gestionperfil/EliminarArchivos', formData)
  }

}
