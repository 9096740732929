import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})

/**
 * Clase que permite interceptar las peticiones HTTP y gestionar la URL
 */
export class BaseUrlInterceptor implements HttpInterceptor {

  /**
   * Constructor del interceptor
   */
  constructor() { }

  /**
   * Interceptador que permite gestionar los errores
   * @param req Request de la solicitud
   * @param next Controlador de evento
   * @returns Peticion
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = this.montaUrl(req);
    const lenguaje = localStorage.getItem('lang');

    req = req.clone({ url });

    // Se verifica si la peticion conlleva llamar a las apis
    if (req.url.includes('api/')) {
      req = req.clone({
        setHeaders: {
          lenguaje: lenguaje,
          'Cache-Control':'no-cache, no-store, must-revalidate','Pragma':'no-cache','Expires':'0'
        },
      });

      if (localStorage.getItem('user_token') != null) {
        const user_token = 'Basic ' + localStorage.getItem('user_token');
        req = req.clone({
          setHeaders: {
            authorization: user_token,
          }
        });
      }

    }

    return next.handle(req);
  }


  /**
   * Método que se encarga de montar la url
   * @param req Request de la solicitud
   * @returns url
   */
  private montaUrl(req: HttpRequest<any>) {
    let url = "";

    if (req.url.includes('traducciones')) {
      url = req.urlWithParams;
    }
    else {
      url = environment.generalURL + req.urlWithParams;
    }

    return url;
  }

}
