import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './vistas/auth/login/login.component';
import { LoginAdminComponent } from './vistas/auth/login-admin/login-admin.component';
import { ClienteFichaComponent } from './vistas/cliente-ficha/cliente-ficha.component';
import { ModuloAdministracionComponent } from './vistas/modulo-administracion/modulo-administracion.component';
import { GuardFichaGuard } from './guards/ficha/guard-ficha.guard';
import { GuardAdminGuard } from './guards/administracion/guard-admin.guard';
import { PolPrivComponent } from './vistas/pol-priv/pol-priv.component';
import { CondGeneralesComponent } from './vistas/cond-generales/cond-generales.component';
import { PolSeguridadComponent } from './vistas/pol-seguridad/pol-seguridad.component';



/**
 * Definición de las rutas de la aplicación
 */
const routes: Routes =
[
  // Vista defecto, se redirige a login
  {
    path: '', pathMatch:'full', redirectTo: '/login'
  },

  // Vista login general
  {
    path: 'login',

    children: [
      {
        path: "",
        component: LoginComponent,
      },
    ]
  },

  // Vista login exlusiva administradores
  {
    path: 'loginAdmin',
    component: LoginAdminComponent
  },

  // Vista ficha clientes
  {
    path: 'ficha-cliente/:id',
    component: ClienteFichaComponent, canActivate: [GuardFichaGuard]
  },

  // Vista modulo administración
  {
    path: 'administracion',
    component: ModuloAdministracionComponent, canActivate: [GuardAdminGuard]
  },

   // Vista politicas de privacidad
   {
    path: 'privacy-policy',
    component: PolPrivComponent,
  },

   // Vista general condciiones
   {
    path: 'general-conditions',
    component: CondGeneralesComponent,
  },

  // Vista politicas de seguridad
  {
    path: 'security-policy',
    component: PolSeguridadComponent,
  },

  // Redireccionamos cualquier otra ruta a la por defecto
  {
    path: '**', redirectTo: '/login'
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})

export class AppRoutingModule {}
