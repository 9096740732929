import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pol-seguridad',
  templateUrl: './pol-seguridad.component.html',
  styleUrls: ['./pol-seguridad.component.scss']
})
export class PolSeguridadComponent implements OnInit {

  
  constructor() { }

  ngOnInit(): void {
  }

  //URL para mostrar el logo que tenemos almacenada dentro de recursos
  urlLogo = environment.recursos + '/nuevoestiloweb/logos/logo_tipos_destinux.svg';

}
