<div class="input-group">

  <span [ngClass]="claseSpan">
    <mat-icon [class]="icono">{{nombreIcono}}</mat-icon>
  </span>

  <input
    [id]="id"
    [name]="nombre"
    [placeholder]="placeholder"
    [ngClass]="clase"
    [required]="isRequired"
    [type]="type"
    [value]="control.value"
    (input)="onInputChange($event)"
  />
</div>


