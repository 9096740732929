import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { IPais } from 'src/app/models/general/pais';
import { IDiccionarios, IItem } from 'src/app/models/interfaces-apis/diccionarios';
import { IFichaCliente } from 'src/app/models/interfaces-apis/ficha_cliente';
import { GeneralService } from 'src/app/servicios/general.service';
import { ItemConverter } from 'src/app/shared/item-converter';
import { Util } from 'src/app/shared/util';
import { SelectSearchMaterialComponent } from '../../../form-componentes/material-framework/select-search-material/select-search-material.component';
import { IExpresion } from 'src/app/models/General/expresion-regular';
import { validarFechaMayor18 } from 'src/app/shared/validaciones';
import { SeccionDocumentoComponent } from '../documentos/documento/seccion-documento.component';
import { TarjetaFidelizacionComponent } from '../documentos/tarjetas-fidelizacion/tarjeta-fidelizacion.component';

@Component({
  selector: 'app-formulario-usuario',
  templateUrl: './formulario-usuario.component.html',
  styleUrls: ['./formulario-usuario.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class FormularioUsuarioComponent implements OnInit {

  @ViewChildren(SelectSearchMaterialComponent) Selects: QueryList<SelectSearchMaterialComponent>;

  @ViewChild('seccion_documentos', { read: ViewContainerRef }) viewContainerRef: ViewContainerRef;

  @ViewChild('Seccion_Tarjeta', { read: ViewContainerRef }) viewContainerRef2: ViewContainerRef;

  @ViewChild('updateButton', { static: true }) updateButton: ElementRef<HTMLButtonElement>;


  @Output() formularioChanges = new EventEmitter<boolean>();
  
  /**
 * Indica la lista de diccionarios a utilizar
 */
  @Input() diccionarios: IDiccionarios[] = [];

  @Input() clienteFicha: IFichaCliente = null;

  @Input() lectura: boolean = false

  @Input() listaPaises: IItem[] = [];
  @Input() expresionesRegulares: IExpresion;
  @Input() listaPrefijos: IItem[];
  @Input() listaComunidades: IItem[];
  


  instanciaSeccionDocumentos: any = null

  instanciaTarjetaFidelizacion: any = null

  /**
   * Formulario
   */
  form: any = null;


  /**
 * Fecha maxima a seleccionar como nacimiento
 */
  maximaFechaNacimientoPermitida: Date = Util.obtenerFechaResta(17);

  /**
   * Fecha minima a seleccionar como nacimiento
   */
  minimaFechaNacimientoPermitida: Date = Util.obtenerFechaResta(82);

 



  /**
   * Constructor del componente
   * @param _formBuilder: Manejador del formulario
   */
  constructor(private readonly _formBuilder: FormBuilder, private _generalService: GeneralService) {
   
    
  }

  /**
   * Carga del componente
   */
  async ngOnInit() {
    setTimeout(() => {
      this.updateButton.nativeElement.click();
    }, 1000);
  }

  //#region METODOS PRIVADOS
  /**
   * Método que permite inicializar el formulario
   */
  private inicializaFormulario() {
    this.form = this._formBuilder.group({
      nombre: ['', [Validators.required, Validators.pattern(this.expresionesRegulares?.nombre_apellidos), Validators.minLength(2), Validators.maxLength(60)]],
      apellidos: ['', [Validators.required, Validators.pattern(this.expresionesRegulares?.nombre_apellidos), Validators.minLength(2), Validators.maxLength(60)]],
      telefono: ['', [Validators.required, Validators.pattern(this.expresionesRegulares?.telefono_empresa), Validators.minLength(4), Validators.maxLength(13)]],
      email: ['', [Validators.required, Validators.email]],
      fechaNacimiento: ['', [Validators.required, validarFechaMayor18]],
      genero: ['', [Validators.required]],
      direccion: ['', [Validators.pattern(this.expresionesRegulares?.direccion), Validators.minLength(2), Validators.maxLength(60)]],
      ciudad: ['', [Validators.pattern(this.expresionesRegulares?.nombre_propios), Validators.minLength(2), Validators.maxLength(60)]],
      codigoPostal: ['', [Validators.pattern(this.expresionesRegulares?.codigo_postal), Validators.minLength(2), Validators.maxLength(20)]],
      provincia: ['', [Validators.pattern(this.expresionesRegulares?.nombre_propios), Validators.minLength(2), Validators.maxLength(20)]],
      pais: ['', [Validators.required]],
      prefijo: ['', [Validators.required]],
    });
    this.form.valueChanges.subscribe(() => { this.formularioCambiado() });
    console.log(this.form)
  }
  actualizarTabla() {}

  //Actualizamos el Formualario completo
  actualizar() {
    if (this.instanciaSeccionDocumentos == null && this.instanciaTarjetaFidelizacion == null) {
      this.insertElementDocumentos()
      this.insertElementTarjetaFidelizacion()
    }

    if(this.form == null)
    {
      this.inicializaFormulario();
    }
  
    this.form.patchValue({
      nombre: this.clienteFicha?.Nombre,
      apellidos: this.clienteFicha?.Apellidos,
      telefono: this.clienteFicha?.Telefono,
      email: this.clienteFicha?.Correo,
      fechaNacimiento: this.clienteFicha?.FechaNacimiento,
      genero: this.clienteFicha?.Genero?.toString(),
      direccion: this.clienteFicha?.Direccion,
      ciudad: this.clienteFicha?.Poblacion,
      codigoPostal: this.clienteFicha?.CodigoPostal,
      provincia: this.clienteFicha?.Provincia,
      pais: this.clienteFicha?.Pais?.toString().toLowerCase(),
      prefijo: this.clienteFicha?.PreFijo,
    })

    this.instanciaSeccionDocumentos.instance.TiposDocumentos = this.diccionarios['TipoDocumentos']
    this.instanciaSeccionDocumentos.instance.TiposFamiliaNumerosa = this.diccionarios['TipoFamiliaNumerosa']
    this.instanciaSeccionDocumentos.instance.Residentes = this.diccionarios['Residentes']
    this.instanciaSeccionDocumentos.instance.DocumentosClientes = this.clienteFicha.Documentos
    this.instanciaSeccionDocumentos.instance.lectura = this.lectura
    this.instanciaSeccionDocumentos.instance.listaPaises = this.listaPaises
    this.instanciaSeccionDocumentos.instance.listaComunidades = this.listaComunidades
    this.instanciaSeccionDocumentos.instance.expresionesRegulares = this.expresionesRegulares

    this.instanciaTarjetaFidelizacion.instance.TiposProductos = this.diccionarios['TipoProductos']
    this.instanciaTarjetaFidelizacion.instance.AereoLineas = this.diccionarios['AereoLineas']
    this.instanciaTarjetaFidelizacion.instance.Alianzas = this.diccionarios['Alianzas']
    this.instanciaTarjetaFidelizacion.instance.Companias = this.diccionarios['Companias']
    this.instanciaTarjetaFidelizacion.instance.DocumentosClientes = this.clienteFicha.TarjetasFidelizacion
    this.instanciaTarjetaFidelizacion.instance.lectura = this.lectura
    this.instanciaTarjetaFidelizacion.instance.expresionesRegulares = this.expresionesRegulares
    


    this.instanciaSeccionDocumentos.instance.actualizar(this.clienteFicha.Documentos)
    this.instanciaTarjetaFidelizacion.instance.actualizarTarjeta(this.clienteFicha.TarjetasFidelizacion)
    this.Selects.forEach(componente => { componente.actualizar() });
  }

  //Creamos dinámicamente el componente de Documentos
  insertElementDocumentos(): void {
    this.viewContainerRef.clear();
    this.instanciaSeccionDocumentos = this.viewContainerRef.createComponent(SeccionDocumentoComponent)
  }

  //Creamos dinámicamente el componente de Tarjetas
  insertElementTarjetaFidelizacion(): void {
    this.viewContainerRef2.clear();
    this.instanciaTarjetaFidelizacion = this.viewContainerRef2.createComponent(TarjetaFidelizacionComponent)
  }

  //Obtenemos los datos del usuario tanto los Datos personales, Documentos y Tarjetas
  obtenerFichaUsuario(): IFichaCliente {
    let clienteActualizar: IFichaCliente = this.obtenerDatosUsuarioFormulario();
    clienteActualizar.Documentos = this.instanciaSeccionDocumentos.instance.obtenerDocumentos();
    clienteActualizar.TarjetasFidelizacion = this.instanciaTarjetaFidelizacion.instance.obtenerTarjetas();
    return clienteActualizar;
  }

  //Obtener los valores de los Datos Personales
  obtenerDatosUsuarioFormulario(): IFichaCliente {
    let clienteFicha: IFichaCliente = Util.crearInstanciaFichaCliente();

    clienteFicha.Nombre = this.form.get('nombre').value
    clienteFicha.Apellidos = this.form.get('apellidos').value
    clienteFicha.Telefono = this.form.get('telefono').value
    clienteFicha.Correo = this.form.get('email').value
    clienteFicha.FechaNacimiento = Util.obtenerFechaDesdeControl(this.form.get('fechaNacimiento').value)
    clienteFicha.Genero = this.form.get('genero').value
    clienteFicha.Direccion = this.form.get('direccion').value
    clienteFicha.Poblacion = this.form.get('ciudad').value
    clienteFicha.CodigoPostal = this.form.get('codigoPostal').value
    clienteFicha.Provincia = this.form.get('provincia').value
    clienteFicha.Pais = this.form.get('pais').value
    clienteFicha.PreFijo = this.form.get('prefijo').value
    return clienteFicha;
  }

  //Cuando hay algún cambio que siga el formulario siendo válido
  formularioCambiado() {
    this.formularioChanges.emit(this.form.valid);
  }

  obtenerDatosDiccionario(clave: string) {
    if (this.diccionarios.length > 0) {
      return this.diccionarios[clave]
    }
    return [];
  }


 

  //#endregion


}
