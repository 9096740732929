import { Injectable } from '@angular/core';
import { ServiceHttpService } from './service-http.service.';
import { IPais } from '../models/general/pais';
import { COMUNIDADES_AUTONOMAS, EXPRESIONES_REGULARES, PAISES, POLITICAS_CONDICIONES } from '../models/constantes';
import { ICodigoComunidad } from '../models/General/comunidad-autonoma';
import { IExpresion } from '../models/General/expresion-regular';
import { IPoliticasCondiciones } from '../models/General/politica-condiciones';
import { ServicioGeneralService } from './servicio-general.service';

@Injectable({
  providedIn: 'root'
})

/**
 * Servicio que se encarga de gestionar las consultas generales
 */
export class GeneralService
{

  /**
   * Constructor de la clase
   * @param _httpService: Servicio http base
   */
  constructor(private _httpService: ServiceHttpService, private _servicioGeneral: ServicioGeneralService) { }

  /**
   * Método que permite cargar la información de los paises 
   * @returns
   */
  async cargarPaises(): Promise<IPais[]>
  {
    return await (await fetch(PAISES)).json();
  }

  /**
   * Método que permite cargar la información de las comunidades 
   * @returns
   */
  async cargarComunidades(): Promise<ICodigoComunidad[]>
  {
    return await (await fetch(COMUNIDADES_AUTONOMAS)).json();
  }

  /**
   * Método que permite cargar la información de las expresiones regulares 
   * @returns
   */
  async cargarExpresionRegulares(): Promise <IExpresion>
  {
    return await (await fetch(EXPRESIONES_REGULARES)).json();
  }

  /**
   * Método que permite cargar la información de las politicas y condiciones 
   * @returns
   */
  async cargarPoliticasYCondiciones(): Promise <IPoliticasCondiciones>
  {
    let idioma = this._servicioGeneral.getLenguaje()
    let rutasPoliticas = POLITICAS_CONDICIONES.replace("idioma",idioma)
    return await (await fetch(rutasPoliticas)).json();
  }
  

}
