<div class="c-card g--margin-t-15">
    <div class="c-card__body g--margin-b-30 row">

        <!-- Grupo Compañia Alquiler de Rent a Car -->
        <div class="g--margin-t-30 col-12 col-lg-4 g--margin-r-30">
            <div class="g--margin-t-30 c-section-altnative c-table">
                <div class="c-section-alternative__header">
                    <b>{{"precliente.formulario.rentacar.compañiadealquiler"|translate}}</b>
                </div>
                <div class="c-section__body g--margin-t-40">
                    <app-select-search-material
                    #Grupo_Preferencia_RentACar_CompaniasAlquiler
                        [required]="false"
                        [buscador]="true"
                        [multiple]="true"
                        [opciones]="Companias"
                        id="CadenasHoteleras"
                        clase="form-control form-control-md"
                        claseIcono=""
                        label="{{'admin.comun.buscar' | translate }}"
                        placeholderBuscador="{{'admin.comun.buscar' | translate }}"
                        placeholderSelector="{{'admin.comun.buscar' | translate }}"
                    >
                    </app-select-search-material>
                </div>
            </div>
        </div>

        <!-- Grupo Observaciones de Rent a Car -->
        <div class="g--margin-t-30 col-12 col-lg-4 l-flex l-flex--direction-column l-flex--space-between">
            <div class="g--margin-t-30 c-section-altnative c-table">
                <div class="c-section-alternative__header">
                    <app-observaciones
                    #Grupo_Preferencia_RentACar_Observaciones
                    [(textareaValue)] = "texto"
                    (textareaValueChange)="actualizarValorObservacion($event)"
                    ></app-observaciones>
                </div>
            </div>
        </div>
    </div>
</div>