import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-observaciones',
  templateUrl: './observaciones.component.html',
  styleUrls: ['./observaciones.component.scss']
})
export class ObservacionesComponent implements OnInit {


  @Input() textareaValue: string = '';
  @Output() textareaValueChange = new EventEmitter<string>();

  contador: string = '0/100';


  constructor() {
  }

  ngOnInit(): void {
  }

  /**
   * Nos permite actualziar el contador de palabras en las observaciones
   */
  actualizarContador(texto: string): void {
    const longitudMax: string | null = '100';
    let longitudAct: number = 0;
    if(texto != null &&  texto != undefined){
      longitudAct = texto.length
    }
    this.contador = `${longitudAct}/${longitudMax}`;
    this.textareaValueChange.emit(this.textareaValue);
  }

  /**
   * Obtener el valor del textarea para añadirlo a las preferencias
   */
  obtenerPreferencias(): string | null {
    let observacion: string | null = null;
    if (this.textareaValue !== null && this.textareaValue.length > 0) {
      observacion = this.textareaValue;
    }
    return observacion;
  }

  actualizar(texto: string) {
    this.textareaValue = texto
    this.actualizarContador(texto);
  }


}
