<div class="c-table g--margin-t-40" >

    <!-- Nombre de la empresa -->
    <div class="c-titulo--md">
        <p>{{'precliente.tabla.subtitulo-tabla' |translate }} <strong>{{empresa?.Nombre}}</strong></p>
    </div>

    <!-- Información de Estados -->
    <div class="l-flex g--margin-b-15">
        <div *ngFor="let option of estadosFiltrados" class="g--margin-r-40 g--margin-t-15 l-flex l-flex__aling-items--center">
            <i class="pi pi-circle-fill  g--margin-r-6 {{colorEstado(option.num)}}"></i>
            <div class="g--margin-r-6">{{"precliente.tabla.estado."+nombreEstado(option.num)|translate}}</div>
            <mat-icon class="g--color-principal-11" pTooltip="{{infoEstado(option.num)}}">info</mat-icon>
        </div>
    </div>

    <!-- Tabla de usuarios -->
    <p-table  [value]="usuarios" [rows]="10" [rowsPerPageOptions]="[10,20,30,40,50]" [paginator]="usuarios.length > 0"
        responsiveLayout="scroll" scrollHeight="auto">
        <ng-template pTemplate="header">
            <tr>
                <th class="g--padding-0-1-rem">{{"precliente.tabla.nombre"|translate}}</th>
                <th class="g--padding-0-1-rem">{{"precliente.select.empresa"|translate}}</th>
                <th class="g--padding-0-1-rem">Dni</th>
                <th class="g--padding-0-1-rem">{{"precliente.tabla.titulo-estado"|translate}}</th>
                <th class="g--padding-0-1-rem"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-cliente>
            <tr>
                <td class="g--padding-0-1-rem">
                    {{cliente.Nombre}}
                </td>
                <td class="g--padding-0-1-rem">
                    {{cliente.NombreEmpresa}}
                </td>
                <td class="g--padding-0-1-rem">
                    {{cliente.NumeroIdentificacion}}
                </td>
                <td class="g--padding-0-1-rem">
                    <i class="pi pi-circle-fill {{colorEstado(cliente.Estado)}}"></i>
                </td>
                <td>
                    <button  *ngIf="cliente.Estado == 2 || cliente.Estado == 3" class="g--padding-0-1-rem"style="cursor: pointer;" (click)="cargaCliente(cliente)" data-bs-toggle="modal"   data-bs-toggle="modal" data-bs-target="#exampleModal"  class="c-button-documentos">
                        <mat-icon>visibility</mat-icon>
                    </button>       
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="5" class="g--padding-0-1-rem">
                    {{"admin.comun.sin-resultados-texto"| translate}}
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<!-- Modal para visualizar ficha del cliente -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" >Ficha Usuario</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
            </div>
            <div class="modal-body" id="seccionFormulario">
                <div #formulario>
                </div>
            </div>
        </div>
    </div>
</div>