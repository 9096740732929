import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ICodigoComunidad } from 'src/app/models/General/comunidad-autonoma';
import { IPais } from 'src/app/models/general/pais';
import { Archivo, IDocumento, IFichaCliente } from 'src/app/models/interfaces-apis/ficha_cliente';
import { GeneralService } from 'src/app/servicios/general.service';
import { ItemConverter } from 'src/app/shared/item-converter';
import { Validaciones, } from 'src/app/shared/validaciones';
import { validarFechaCaducidad } from 'src/app/shared/validaciones';
import { validarFechaExpedicion } from 'src/app/shared/validaciones';
import { Util } from 'src/app/shared/util';
import { IItem, IResidente } from 'src/app/models/interfaces-apis/diccionarios';
import { ETipoAlerta, ETipoDocumento } from 'src/app/models/enums.enum';
import { PAIS_ESPANA } from 'src/app/models/constantes';
import { AlertaService } from 'src/app/shared/alerta.service';
import { IExpresion } from 'src/app/models/General/expresion-regular';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuidv4 } from 'uuid'; 
import { UserService } from 'src/app/servicios/user.service';
import { error } from 'console';
import { FileUpload } from 'primeng/fileupload';
import { HttpResponse } from '@angular/common/http';
@Component({
  selector: 'app-seccion-documento',
  templateUrl: './seccion-documento.component.html',
  styleUrls: ['./seccion-documento.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SeccionDocumentoComponent implements OnInit {

  @ViewChild('table') table: MatTable<any>;
  @ViewChild('updateButton', { static: true }) updateButton: ElementRef<HTMLButtonElement>;
  @ViewChild('modal') modal!: ElementRef;
  @ViewChild('CargarDocumento') CargarDocumento :FileUpload
  form: FormGroup;

  @Input() TiposDocumentos: IItem[]

  @Input() TiposFamiliaNumerosa: IItem[]

  @Input() Residentes: IResidente[]

  @Input() DocumentosClientes: IDocumento[]

  @Input() clienteFicha: IFichaCliente;

  @Input() lectura: boolean = false
  
  @Input() listaPaises: IItem[] = [];
  @Input() listaComunidades: IItem[];
  
  ETipoDocumento = ETipoDocumento;

  PAIS_ESPANA = PAIS_ESPANA;

  @Input() expresionesRegulares: IExpresion;

  /**
 * Fecha maxima a seleccionar como nacimiento
 */
  maximaFechaNacimientoPermitida: Date = Util.obtenerFechaResta2(0, 2040);

  /**
   * Fecha minima a seleccionar como nacimiento
   */
  minimaFechaNacimientoPermitida: Date = Util.obtenerFechaResta2(82, 2024);


  listaResidentes: IItem[] = [];
  residentesConvertidos: boolean = false;

  modalAbierto: boolean = false;
  uploadedFiles: File[] = [];
  ficherosExistentes: any = [];
  ficherosEliminados: any = [];
  uuid: string = '';
  archivos: Archivo[] = [];
  instanciaArchivosFormulario: any

  documentoForms: FormGroup[] = [];
  columnas: string[] = ['TipoDocumento', 'PaisExpedicion', 'NumDocumento', 'FechaExpedicion', 'FechaCaducidad', 'Acciones', 'PaisNacionalidad', 'InfoFamiliaNumerosaES.CodigoComunidad', 'InfoFamiliaNumerosaES.Tipo'];
  dataSource: MatTableDataSource<AbstractControl>
  constructor(private fb: FormBuilder, private _generalService: GeneralService, private _alertaService: AlertaService, private _translate: TranslateService, private _userService: UserService, private cdRef: ChangeDetectorRef ) {
    this.form = this.fb.group({
      forms: this.fb.array([]),
    });
  }

  async ngOnInit(): Promise<void> {
    setTimeout(() => {
      this.updateButton.nativeElement.click();
    }, 1000);

  }

  // Crea un formulario reactivo para cada documento en la lista
  actualizar(Documentos: IDocumento[]) {
    this.documentoForms = []
      this.DocumentosClientes = Documentos
      this.DocumentosClientes.forEach(doc => {
        const form = this.fb.group({
          TipoDocumento: [doc.TipoDocumento.toString(), Validators.required],
          NumDocumento: [doc.NumDocumento, [Validators.required, Validators.pattern(this.expresionesRegulares?.validacionNumeroDocumento)]],
          PaisExpedicion: [doc.PaisExpedicion?.toLowerCase(),[Validators.required]],
          FechaExpedicion: [doc.FechaExpedicion, [validarFechaExpedicion]],
          FechaCaducidad: [doc.FechaCaducidad, [validarFechaCaducidad]],
          PaisNacionalidad: [doc.PaisNacionalidad?.toLowerCase()],
          InfoFamiliaNumerosaES: this.fb.group({
            Tipo: [doc.InfoFamiliaNumerosaES?.Tipo.toString()],
            CodigoComunidad: [doc.InfoFamiliaNumerosaES?.CodigoComunidad.toString()],
          }),
          Guid: [doc.Guid],
          Archivos: [doc.Archivos]

        });

        this.documentoForms.push(form);

      });
      this.form = this.fb.group({
        forms: this.fb.array(this.documentoForms),
      });

      this.dataSource = new MatTableDataSource(this.formsControlArray.controls);
    
  }


  //Eliminamos Documento del form y actualizamos la tabla
  eliminarDocumento(index: number) {
    this.formsControlArray.removeAt(index);
    this.table.renderRows();
  }

  //Alerta y eliminamos el Documento seleccionado
  cancelarEdicion(index: number) {
    this._alertaService.eliminarDocumento(() => this.eliminarDocumento(index));

  }

  //Crea un nuevo Documento
  crearFila() {
    const filas = this.formsControlArray;
    filas.push(
      this.fb.group({
        TipoDocumento: ["", Validators.required],
        NumDocumento: ["", [Validators.required,Validators.pattern(this.expresionesRegulares?.validacionNumeroDocumento)]],
        PaisExpedicion: ["", Validators.required],
        FechaExpedicion: ["", [validarFechaExpedicion]],
        FechaCaducidad: ["", [validarFechaCaducidad]],
        PaisNacionalidad: [""],
        InfoFamiliaNumerosaES: this.fb.group({
          Tipo: [""],
          CodigoComunidad: [""]
        }),
        Guid: uuidv4(),
        Archivos: [[]]
      })
    );
  }

  //Creamos el Documento y actualizamos la tabla para que se muestre
  crearDocumento() {
    this.crearFila();
    this.table.renderRows();
  }

  //Acceder a un formulario y tratarlo como FormArray
  get formsControlArray() {
    return this.form.get('forms') as FormArray;
  }

  //Validamos que el NIE tenga un formato esperado
  validarNIE(numeroDocumento: string): boolean {
    return Validaciones.validarNIE(numeroDocumento);
  }

  //Obtener la lista de Residentes con el codigo y la localidad
  convertirResidentes() {
    if (this.Residentes && this.residentesConvertidos === false) {
      this.listaResidentes = ItemConverter.transformarObjetoToItem(this.Residentes, 'Codigo', 'Localidad');
      this.residentesConvertidos = true;
    }
    return this.listaResidentes;
  }

  //Obtener cada valor del Documento
  obtenerDocumentos(): IDocumento[] {
    const documentos: IDocumento[] = this.formsControlArray.controls.map(form => {
      let FamiliaNumerosa = form.get('InfoFamiliaNumerosaES').value
      let TipoDocumento = form.get('TipoDocumento').value
      let PaisExpedicion = form.get('PaisExpedicion').value?.toUpperCase()
      let FechaCaducidad = Util.obtenerFechaDesdeControl(form.get('FechaCaducidad').value);
      let FechaExpedicion = Util.obtenerFechaDesdeControl(form.get('FechaExpedicion').value);
      let uuid = form.get('Guid')?.value
      let Archivos = form.get('Archivos')?.value
      const documento: IDocumento = {
        TipoDocumento: TipoDocumento,
        NumDocumento: form.get('NumDocumento').value,
        PaisExpedicion: PaisExpedicion,
        FechaExpedicion: FechaExpedicion,
        FechaCaducidad: FechaCaducidad,
        PaisNacionalidad: form.get('PaisNacionalidad').value,
        InfoFamiliaNumerosaES: this.obtenerFamiliaNumerosa(TipoDocumento, PaisExpedicion, FamiliaNumerosa),
        Guid: uuid,
        Archivos: Archivos
      }
      return documento;
    });
    return documentos;
  }

  //Validamos que no haya algún campo inválido en nuestro formulario
  validarFormulariosDocuemntos() {
    return !this.formsControlArray.controls.some(form => form.invalid)
  }


  obtenerFamiliaNumerosa(TipoDocumento, PaisExpedicion, form) {
    if (TipoDocumento == ETipoDocumento.FamiliaNumerosa.toString() && PaisExpedicion == PAIS_ESPANA.toUpperCase()) {
      return form;
    }
    return null;
  }

  //Validar que no haya Documentos duplicados 
  validarDocumentos(valor: string, tipo: number, form: any) {
    let existe = false
    if (tipo == 0) {

      existe = this.verificarDuplicados(valor, tipo, "TipoDocumento", "PaisExpedicion")
    } else {
      existe = this.verificarDuplicados(valor, tipo, "PaisExpedicion", "TipoDocumento")
    }
    

    if (existe) {
      this._alertaService.mostrarAlertaConTitulo(this._translate.instant('precliente.alert.mensaje-duplicado'), this._translate.instant('perfil.error.bonificaciones-duplicadas'), ETipoAlerta.Warning)
    }

    if(form.get('TipoDocumento').value == ETipoDocumento.Residente){
      form.patchValue({
        NumDocumento: null
    })
    }
    this.validarCamposFormularioSegunTipoDocumento(form)
  }

  //Validacion de NIE, ya que Nacionalidad y País de expedición tienen que ser diferentes
  validarNieNacionaldiad(row: any) {
    if (row.get('PaisNacionalidad').value == PAIS_ESPANA &&
      row.get('TipoDocumento').value == ETipoDocumento.NumIdentificacion &&
      row.get('PaisExpedicion').value == PAIS_ESPANA) {
      this._alertaService.mostrarAlertaConTitulo(this._translate.instant('precliente.alert.problema-nacionalidad'), this._translate.instant('perfil.error.nie-nacionalidad'), ETipoAlerta.Warning)
    }
  }

  //Funcion para poder validar los Documentos duplicados
  verificarDuplicados(valor: string, tipo: number, campoPrincipal: string, campoSecundario: string) {
    let existe = false
    if (this.formsControlArray.controls.filter(form => form.get(campoPrincipal).value == valor).length > 1) {
      let encontrados = this.formsControlArray.controls.filter(form => form.get(campoPrincipal).value == valor)

      let lista = []
      encontrados.forEach(form => {
        let Item = form.get(campoSecundario).value
        if (Item !== null && lista.includes(Item)) {
          if (!(tipo == 0 && valor == ETipoDocumento.Pasaporte.toString()) && !(tipo == 1 && Item == ETipoDocumento.Pasaporte.toString())) {
            existe = true
          }
        }
        lista.push(Item)
      });
    }
    return existe
  }

  actualizarTabla() {}


  //Función para poder cambiar las validaciones dependiendo de algunas condiciones
  validarCamposFormularioSegunTipoDocumento(form: any) {

    const TipoDocumento = form.get('TipoDocumento').value;
    const fechaExpedicionControl = form.get('FechaExpedicion');
    const fechaCaducidadControl = form.get('FechaCaducidad');
    const FormGroupFamiliaNumerosa = form.get('InfoFamiliaNumerosaES')
    const FamiliaNumerosaComunidad = FormGroupFamiliaNumerosa.get('CodigoComunidad')
    const FamiliaNumerosaRegimen = FormGroupFamiliaNumerosa.get('Tipo')
    const PaisExpedicion = form.get('PaisExpedicion')
    const PaisNacionalidad = form.get('PaisNacionalidad')
    const NumDocumento = form.get('NumDocumento')

    if (TipoDocumento == ETipoDocumento.NumIdentificacion) {
      FamiliaNumerosaComunidad.clearValidators();
      FamiliaNumerosaRegimen.clearValidators();
      fechaExpedicionControl.clearValidators();
      FamiliaNumerosaComunidad.updateValueAndValidity();
      FamiliaNumerosaRegimen.updateValueAndValidity();
      fechaExpedicionControl.updateValueAndValidity();
      fechaExpedicionControl.setValidators([validarFechaExpedicion]);
      fechaCaducidadControl.setValidators([Validators.required, validarFechaCaducidad]);
      
   
    } else if (TipoDocumento == ETipoDocumento.Residente) {
      FamiliaNumerosaComunidad.clearValidators();
      FamiliaNumerosaRegimen.clearValidators();
      PaisNacionalidad.clearValidators();
      FamiliaNumerosaComunidad.updateValueAndValidity();
      FamiliaNumerosaRegimen.updateValueAndValidity();
      PaisNacionalidad.updateValueAndValidity();
      fechaExpedicionControl.clearValidators();
      fechaCaducidadControl.clearValidators();
      fechaExpedicionControl.setValidators([validarFechaExpedicion]);
      fechaCaducidadControl.setValidators([validarFechaCaducidad]);

    } else if (TipoDocumento == ETipoDocumento.Residente && PaisExpedicion == PAIS_ESPANA) {
      NumDocumento.setValidators([Validators.required]);
      NumDocumento.updateValueAndValidity();
     
    } else if (TipoDocumento == ETipoDocumento.FamiliaNumerosa && PaisExpedicion == PAIS_ESPANA) {
      FamiliaNumerosaComunidad.setValidators([Validators.required]);
      FamiliaNumerosaRegimen.setValidators([Validators.required]);
      FamiliaNumerosaComunidad.updateValueAndValidity();
      FamiliaNumerosaRegimen.updateValueAndValidity();
      } 
    
    else {
      FamiliaNumerosaComunidad.clearValidators();
      FamiliaNumerosaRegimen.clearValidators();
      PaisNacionalidad.clearValidators();
      FamiliaNumerosaComunidad.updateValueAndValidity();
      FamiliaNumerosaRegimen.updateValueAndValidity();
      PaisNacionalidad.updateValueAndValidity();
      fechaExpedicionControl.setValidators([Validators.required, validarFechaExpedicion]);
      fechaCaducidadControl.setValidators([Validators.required, validarFechaCaducidad]);
    }
    fechaExpedicionControl.updateValueAndValidity();
    fechaCaducidadControl.updateValueAndValidity();
  }

 
  //Abrir el modal para poder adjuntar Documentos
  abrirModal(form: any): void {
    this.uuid = form.get('Guid').value;
    console.log(this.uuid)
    this.ficherosExistentes = []
    if(form.get('Archivos') !== null){
    this.instanciaArchivosFormulario = form
    this.ficherosExistentes = form.get('Archivos').value.filter(f => !this.ficherosEliminados.some(a => a.guid == this.uuid && a.nombre == f.Nombre));
    console.log(this.ficherosExistentes)
    }
    this.CargarDocumento?.clear(); 
    this.modalAbierto = true
    this.modal.nativeElement.classList.add('modalAbierto');
  }

  //Cerrar el modal
  cerrarModal(): void {
    this.modalAbierto = false
    this.modal.nativeElement.classList.remove('modalAbierto');
  }

  //Seleccionar archivos y que se actualicen
  onSelect(event: any) {
    this.uploadedFiles = event.currentFiles;
  }

  //Subir archivo y lo almacenaremos en un guid concreto
  subirArchivo(event: any) {
    const formData: FormData = new FormData();
    formData.append('Guid', this.uuid);

    for (let file of event.files) {
      formData.append(file.name, file, file.name)
      
    }

    this._userService.subirArchivo(formData).subscribe((response) =>
      {
        console.log('Response', response)
        
        this.ficherosExistentes = response;
      
        this.instanciaArchivosFormulario.patchValue({
          Archivos: response,
         
        })
        this.CargarDocumento.clear(); 
        
        this.cdRef.detectChanges();
      })
    
  }
  
  //Eliminar archivo dentro del Modal pero que ya ha sido cargado
  eliminarArchivo(file: any){
    const formData: FormData = new FormData();
    formData.append('Guid', this.uuid);
    formData.append('nombre', file.Nombre)
    console.log('Archivo para eliminar:', formData);
    
    this._userService.eliminarArchivos(formData).subscribe({
      next: (response: any) => {
      this.ficherosExistentes = this.ficherosExistentes.filter(f => f.Nombre !== file.Nombre)
      this.ficherosEliminados.push({'guid' : this.uuid, 'nombre': file.Nombre})
      this.cdRef.detectChanges();
      console.log(this.ficherosExistentes)
      }
    })
  }

  //Eliminar archivo dentro del Modal pero que solo se ha seleccionado y por lo tanto aun no ha sido enviado
  eliminarArchivo2(event: any){
    const formData: FormData = new FormData();
    formData.append('Guid', this.uuid);
    formData.append('nombre', event.file.name)
    console.log('Archivo para eliminar:', formData);
    
    this._userService.eliminarArchivos(formData).subscribe({
      next: (response: any) => {
      }
    })
  }

  //En caso de ser un .pdf nos mostrara una imagen de pdf
  ImagenPdf(nombre: string): boolean {
    const ext = nombre.split('.').pop()?.toLowerCase();
    return ext === 'pdf';
  }

}


    