<footer class="footer">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-md-5 col-lg-4 footer-imagen">
        <app-imagen-footer-login [anyadirClasePy]="false"></app-imagen-footer-login>
      </div>
      
      <!-- Enlaces para poltiicas y condiciones -->
      <div class="col-12 col-md-7 col-lg-8 footer-links">
        <div class="row text-center">
          <div class="col-12 col-lg-3 margin-small__screen">
            <a routerLink="/privacy-policy" target="_blank">{{'login.politica-privacidad' | translate }}</a>
          </div>
          <div class="col-12 col-lg-3 margin-small__screen">
            <a routerLink="/general-conditions" target="_blank">{{'login.condiciones-generales' | translate }}</a> 
          </div>
          <div class="col-12 col-lg-3 margin-small__screen">
            <a routerLink="/security-policy" target="_blank">{{'login.politica-seguridad' | translate }}</a>     
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>