import { Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { IDiccionarios, IItem, IPreferencia } from 'src/app/models/interfaces-apis/diccionarios';
import { IFichaCliente, IPreferenciaCliente } from 'src/app/models/interfaces-apis/ficha_cliente';
import { UserService } from 'src/app/servicios/user.service';
import { FormularioUsuarioComponent } from '../formulario-usuario/formulario-usuario.component';
import { UsuarioPerfilComponent } from '../../perfil/usuario-perfil/usuario-perfil.component';
import { UsuarioPreferenciasComponent } from '../../preferencias/usuario-preferencias/usuario-preferencias.component';
import { AlertaService } from 'src/app/shared/alerta.service';
import { EAccionFormulario, ERuta, ETipoAlerta, ETipoDocumento } from 'src/app/models/enums.enum';
import { MatStepper } from '@angular/material/stepper';
import { Util } from 'src/app/shared/util';
import { HttpResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { IPais } from 'src/app/models/general/pais';
import { IExpresion } from 'src/app/models/General/expresion-regular';
import { FormControl, FormGroup } from '@angular/forms';
import { PoliticasCondicionesComponent } from 'src/app/componentes/politicas-condiciones/politicas-condiciones.component';

@Component({
  selector: 'app-ficha-usuario',
  templateUrl: './ficha-usuario.component.html',
  styleUrls: ['./ficha-usuario.component.scss']
})
export class FichaUsuarioComponent implements OnInit {


  @ViewChild(FormularioUsuarioComponent) formulario: FormularioUsuarioComponent;

  @ViewChild(UsuarioPerfilComponent) seccionperfil: UsuarioPerfilComponent;

  @ViewChild(UsuarioPreferenciasComponent) seccionpreferencias: UsuarioPreferenciasComponent;
  @ViewChild(PoliticasCondicionesComponent) politicasCondiciones: PoliticasCondicionesComponent;

  @ViewChild(MatStepper) stepper: MatStepper;

  

  /**
   * Indica la lista de diccionarios a utilizar
   */

  @Input() diccionarios: IDiccionarios[];
  @Input() clienteFicha: IFichaCliente;
  @Input() listaPaises: IItem[];
  @Input() expresionesRegulares: IExpresion;
  @Input() listaPrefijos: IItem[];
  @Input() listaComunidades: IItem[];
  
  
  GuardarCambiosDeshabilitado: boolean = true
  EAccion = EAccionFormulario
  ConfirmaFinalizacionFormulario: boolean = false


  ngOnInit(): void {
    this.longitudDiccionario = Object.keys(this.diccionarios).length;
  }


  longitudDiccionario: number;

  constructor(private _userService: UserService, private _alertaService: AlertaService, private _translate: TranslateService) {

  }

  //Actualizar todo el formulario completo
  actualizarFormulario() {
    this.formulario.actualizar();
    this.seccionperfil.actualizar();
    this.seccionpreferencias.actualizar();
    
  }

  //Obtener los datos del Formulario
  obtenerDatosFormulario(): IFichaCliente {
    let fichaActualizar: IFichaCliente = this.formulario.obtenerFichaUsuario();
    let preferenciasPerfil: [IPreferenciaCliente[]] = this.seccionperfil.obtenerPreferenciasGuardadas();
    fichaActualizar.Preferencias = [...fichaActualizar.Preferencias, ...preferenciasPerfil[0]];
    fichaActualizar.Newsletter = this.clienteFicha.Newsletter;
    fichaActualizar.FechaAceptacionPoliticas = this.clienteFicha.FechaAceptacionPoliticas;
    fichaActualizar.Preferencias = [...fichaActualizar.Preferencias, ...this.seccionpreferencias.obtenerPreferenciasGuardadas()];
    
    
    return fichaActualizar;
    
  }

  //Volver un paso atras en nuestro mat-step
  clicarVolver() {
    this.stepper.previous();
  }

  //Guardar los datos de neustro Formulario siempre que sea válido
  recopilarDatos(accion: EAccionFormulario = EAccionFormulario.Guardar) {
    if (this.formulario.form.valid && this.formulario.instanciaSeccionDocumentos.instance.validarFormulariosDocuemntos() && this.formulario.instanciaTarjetaFidelizacion.instance.validarFormulariosTarjetas()) {
      let fichaActualizar: IFichaCliente = this.obtenerDatosFormulario();
      let etapa = this.stepper.selectedIndex

      if(this.validarDocumentoObligatorio(fichaActualizar)){
        if (etapa > 0) {
          let estadoPerfilUsuario = this.seccionperfil.validarPreferencias()
          let estadoPreferenciaUsuario = this.seccionpreferencias.validarPreferencias()
  
          if (!estadoPerfilUsuario || !estadoPreferenciaUsuario) {
            this._alertaService.alertaToasts(this._translate.instant('precliente.formulario.alerta-preferencias-obligatorias'), ETipoAlerta.Warning)
          } else {
            if (accion == EAccionFormulario.Finalizar && this.ConfirmaFinalizacionFormulario == false) {
              this._alertaService.finalizarFormulario(this.NombreCliente(fichaActualizar), (() => this.guardarClienteFinalizado(accion, fichaActualizar)))
            } else {
              this.guardarCliente(accion, fichaActualizar)
            }
          }
        } else {
          this.guardarCliente(accion, fichaActualizar)
        }
      }else{
        this._alertaService.mostrarAlerta(this._translate.instant('precliente.formulario.alerta-documento-obligatoria'), ETipoAlerta.Warning)
      }
    } else {
      this._alertaService.alertaToasts(this._translate.instant('precliente.formulario.alerta-datos-incorrectos'), ETipoAlerta.Warning)
      this.campoIncorrecto();

    }

  }


  guardarClienteFinalizado(accion: EAccionFormulario, fichaActualizar: IFichaCliente) {
    fichaActualizar.Finalizado = true
    this.guardarCliente(accion, fichaActualizar)
  }

  //Guardamos los datos del Cliente
  guardarCliente(accion: EAccionFormulario, fichaActualizar: IFichaCliente) {
    this._userService.guardarCliente(fichaActualizar).subscribe({
      next: (response: HttpResponse<any>) => {
        if (accion == EAccionFormulario.Guardar) {
          this._alertaService.alertaToasts(this._translate.instant('travel-manager.modificar-presupuesto.guardado-correcto'))
        }
        else if (accion == EAccionFormulario.Finalizar) {
          this.ConfirmaFinalizacionFormulario == true
          this._alertaService.finalizarSalir(this.NombreCliente(fichaActualizar), this.rederigir)
        } else {
          if (accion == EAccionFormulario.Continuar)
            this.stepper.next();
            window.scrollTo(0, 0);
        }
      },

    })
  }

  //Función para rederigir al Login
  rederigir() {
    Util.redirigir(ERuta.Login)
  }

  //Obtener nombre y apellidos del cliente
  NombreCliente(ficha_cliente: IFichaCliente): string {
    return ficha_cliente.Nombre + " " + ficha_cliente.Apellidos
  }

  //Validar Formulario
  validarFormularioFicha(estado: boolean) {
    this.GuardarCambiosDeshabilitado = estado

  }

  //Validacion de Documento Obligatorio, ya que es necesario para guardar contar al menos con un Documento de Tipo NumIdentificacion o Pasaporte
  validarDocumentoObligatorio(FichaCliente:IFichaCliente ){
    let estado = false;
    if(FichaCliente.Documentos.some(d => d.TipoDocumento == ETipoDocumento.NumIdentificacion || d.TipoDocumento == ETipoDocumento.Pasaporte)){
      estado = true;
    }
    return estado
  }

  //Alguno de los 3 formularios es invalido hara el desplazamiento y el enfoque
  campoIncorrecto(){
    this.ScrollYFocoFormularioInvalido(this.formulario.form).then(() => {
    });
    this.ScrollYFocoFormularioInvalido(this.formulario.instanciaSeccionDocumentos.instance.form).then(() => {
    });
    this.ScrollYFocoFormularioInvalido(this.formulario.instanciaTarjetaFidelizacion.instance.form).then(() => {
    });
    
  }

  //Desplaza la vista y lo enfoca
  ScrollYFoco(el: Element) {
    if(el) { 
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        (el as HTMLElement).focus();
    }
  }
 
  //En el primer campo que tenga un error se desplaza la vista y lo enfoca
  ScrollYFocoError(): void {
    const firstElementWithError = document.querySelector('input.ng-invalid, mat-select.ng-invalid');
    if (firstElementWithError) {
      this.ScrollYFoco(firstElementWithError);
    }
  }
 
  //Si el formulario es invalido se desplaza al primer error y lo enfoca
 async ScrollYFocoFormularioInvalido(form: FormGroup): Promise <any> {
   await form.invalid;
   this.ScrollYFocoError();
  }

}
