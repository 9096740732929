
import { Injectable } from '@angular/core';
import { ETipoErrores, EMensaje, ETipoAlerta } from '../models/enums.enum';
import { TranslateService } from '@ngx-translate/core';
import { AlertaService } from './alerta.service';

@Injectable({
  providedIn: 'root',
})

/**
 * Clase que permite realizar el control de errores de forma general
 */
export class ControlErrorService
{

  /**
   * Constructor del servicio
   * @param _translate: Servicio de traducciones
   * @param _alertaService: Servicio de alerta
   */
  constructor(
    private _translate: TranslateService,
    private _alertaService: AlertaService
  ) {}


  /**
   * Método que permite gestionar los errores de la aplicación
   * @param error Error ocurrido
   */
  errorServidor(error: any)
  {
    let icono: ETipoAlerta = ETipoAlerta.Info;
    let titulo: string;
    let mensaje: string | null = null;

    icono = ETipoAlerta.Error;
    titulo = EMensaje.ErrorComun;

    // Se analizan los errores 400
    if (error.status == 400)
    {
      mensaje = error?.error.Message;
      switch (error?.error.Tipo)
      {
        case ETipoErrores.Error:
          icono = ETipoAlerta.Error;
          titulo = EMensaje.ErrorComun;
          break;
        case ETipoErrores.NoEncontrado, ETipoErrores.SinResultados:
          icono = ETipoAlerta.Info;
          titulo = EMensaje.ErrorSinResultados;
          break;
        case ETipoErrores.DatosPeticionNoValidos, ETipoErrores.Prohibido, ETipoErrores.Warning:
          icono = ETipoAlerta.Warning;
          titulo = EMensaje.ErrorComunAtencion;
          break;
      }
    }

    // Se analiza si el servidor no esta disponible
    if (error.status === -1)
    {
      mensaje = this._translate.instant(EMensaje.ErrorServidorNoDisponible);
    }
    else
    {
      if (error.status < 500)
      {
        if (error.status === 401)
        {
          mensaje = this._translate.instant(EMensaje.ErrorAccesoRestringido);
        }

        if (error.status === 404)
        {
          mensaje = this._translate.instant(EMensaje.ErrorRecurso);
        }
      // Se analizan los errores 500
      }
      else
      {
        if (typeof error.exceptionMessage !== 'undefined' && error.exceptionMessage.includes(':'))
        {
          const resto_error = error.exceptionMessage
            .split(':')
            .slice(1)
            .join(':');
          if (resto_error[0] != 'expression')
          {
            mensaje = error[0] + ': ' + error[1];
          }
        }
        else
        {
          mensaje = this._translate.instant(EMensaje.ErrorPeticion);
        }
      }
    }

    // Se muestra alerta segun el tipo de mensaje
    if (mensaje !== null)
    {
      if (error?.error?.EsHTML)
      {
        this._alertaService.mostrarAlertaHtml(titulo, mensaje, icono);
      }
      else
      {
        this._alertaService.mostrarAlertaConTitulo(titulo, mensaje, icono);
      }
    }
  }

  /**
   * Método que permite mostrar el error ocurrido en la web
   * @param mensaje: Mensaje con el error ocurrido
   */
  errorCliente(mensaje: any)
  {
    const titulo = this._translate.instant(EMensaje.ErrorComun);
    this._alertaService.mostrarAlertaConTitulo(titulo, mensaje, ETipoAlerta.Error);
  }

}
