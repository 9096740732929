<div class="l-flex" style="justify-content: center;" *ngIf="tipo == 'inline'">

  <img *ngFor="let bandera of banderas" src="assets/img/banderas/{{ bandera.nombreIMG }}.svg" [title]="bandera.lenguaje"
    [alt]="bandera.lenguaje" class="bandera--inline"
    [ngClass]="{ 'bandera--no-seleccionada': banderaSeleccionada.lenguaje != bandera.lenguaje }"
    (click)="cambiarLenguaje(bandera.lenguaje)" />

</div>

<ng-container *ngIf="tipo == 'desplegable'">

  <button mat-button [matMenuTriggerFor]="menuIdioma">
    <img src="assets/img/banderas/{{ banderaSeleccionada.nombreIMG }}.svg" title="Español" alt="Español"
      class="bandera--desplegable" />
    <span class="bandera__texto c-text">
      {{ banderaSeleccionada.texto }}
    </span>
  </button>

  <mat-menu #menuIdioma="matMenu">
    <ng-container *ngFor="let bandera of banderas">
      <button mat-menu-item *ngIf="banderaSeleccionada.lenguaje != bandera.lenguaje"
        (click)="cambiarLenguaje(bandera.lenguaje)">
        <img src="assets/img/banderas/{{ bandera.nombreIMG }}.svg" [title]="bandera.lenguaje" [alt]="bandera.lenguaje"
          class="bandera--desplegable" />
        <span class="bandera__texto c-text">
          {{ bandera.texto }}
        </span>
      </button>
    </ng-container>
  </mat-menu>
</ng-container>

<ng-container *ngIf="tipo == 'dropdown'">
  <p-dropdown [options]="banderas" [(ngModel)]="banderaSeleccionada"
    (onChange)="cambiarLenguaje($event.value.lenguaje)">
    <ng-template pTemplate="selectedItem">
      <div class="l-flex l-flex__aling-items--center" *ngIf="banderaSeleccionada">
        <div class="g--margin-r-15">
          <img class="img-bandera-dropdown g--redondeado " src="assets/img/banderas/{{ banderaSeleccionada.nombreIMG }}.svg" />
        </div>
        <div class="c-text">{{ banderaSeleccionada.texto }}</div>
      </div>
    </ng-template>
    <ng-template let-bandera pTemplate="item">
      <div class="l-flex l-flex__aling-items--center">
        <div class="g--margin-r-15">
          <img class="g--redondeado img-bandera-dropdown" src="assets/img/banderas/{{ bandera.nombreIMG }}.svg" />
        </div>
        <div class="c-text">{{ bandera.texto }}</div>
      </div>
    </ng-template>
  </p-dropdown>
</ng-container>
