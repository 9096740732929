import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';



@Component({
  selector: 'app-checkbox-primeNG',
  templateUrl: './checkbox-primeNG.component.html',
  styleUrls: ['./checkbox-primeNG.component.scss']
})
export class CheckboxPrimeNGComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


  radioButtonFumador: any = null;

  @Input() multiple: boolean = true;
  @Input() obtenerOpcionesPreferencia: any[] = [];
  @Input() sectionClass: string = '';


  opcionesSeleccionadas: string[] = [];

  @Output() opcionesSeleccionadasEvent: EventEmitter<string[]> = new EventEmitter();


   /**
   * Emitimos un evento cuando seleccionamos un checkbox
   */
  CheckboxCambio(event: any) {
    this.opcionesSeleccionadasEvent.emit(this.opcionesSeleccionadas);
  }

  obtenerPreferencias() {
    return this.radioButtonFumador;
  }

 /**
   * Actualziar opciones seleccionadas
   */
  actualizar(opcionesSeleccionadas: string[]) {
    this.opcionesSeleccionadas = opcionesSeleccionadas;

  }

   /**
   * En caso de ser radioButton también añadirlas a las ocpiones seleccionadas
   */
  radioButtonCambio(opcionId: string, event: any) {
    let opcionesSeleccionadas = []
    opcionesSeleccionadas.push(opcionId)
    this.opcionesSeleccionadasEvent.emit(opcionesSeleccionadas);
  }
}


