
import { Component, Input } from '@angular/core';
import { IMAGEN_LOGIN_FOOTER } from '../../../models/constantes';

@Component({
  selector: 'app-imagen-footer-login',
  templateUrl: './imagen-footer-login.component.html',
  styleUrls: ['./imagen-footer-login.component.scss']
})

/**
 * Componente que permite pintar la imagen de destinux
 */

export class ImagenFooterLoginComponent
{
  /**
   * Ruta a la imagen de destinux
   */
  imagen = IMAGEN_LOGIN_FOOTER;


  @Input() anyadirClasePy: boolean = false;
}


