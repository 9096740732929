import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ETipoPreferencia } from 'src/app/models/enums.enum';
import { ICompania, IItem, IPreferencia } from 'src/app/models/interfaces-apis/diccionarios';
import { ObservacionesComponent } from '../../../form-componentes/observaciones/observaciones.component';
import { IFichaCliente, IPreferenciaCliente } from 'src/app/models/interfaces-apis/ficha_cliente';
import { SelectSearchMaterialComponent } from '../../../form-componentes/material-framework/select-search-material/select-search-material.component';
import { preferencias } from 'src/app/models/General/preferencias';
import { GrupoPreferenciaComponent } from '../../../form-componentes/grupo-preferencia/grupo-preferencia.component';

@Component({
  selector: 'app-preferencia-aereo',
  templateUrl: './preferencia-aereo.component.html',
  styleUrls: ['./preferencia-aereo.component.scss']
})
export class PreferenciaAereoComponent extends preferencias implements OnInit {

  @ViewChild('Grupo_Preferencia_Aereo_Preferente') Grupo_Preferencia_Aereo_Preferente: GrupoPreferenciaComponent;
  @ViewChild('Grupo_Preferencia_Aereo_Companias') Grupo_Preferencia_Aereo_Companias: SelectSearchMaterialComponent;
  @ViewChild('Grupo_Preferencia_Aereo_Observaciones') Grupo_Preferencia_Aereo_Observaciones: ObservacionesComponent;


  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  numero: number = 0;

  TipoPreferencia = ETipoPreferencia;

  @Input() preferencias: IPreferencia[];
  @Input() AereoLineas: IItem[];

  @Input() clienteFicha: IFichaCliente;


  //Obtener la lista de las preferencias aereas guardadas
  obtenerPreferenciasGuardadas(): IPreferenciaCliente[] {

    let ListaPreferencias: IPreferenciaCliente[] = []

    let tuplaAereoPreferente = this.Grupo_Preferencia_Aereo_Preferente.obtenerPreferencias()
    let preferenciaAereoAsientos: IPreferenciaCliente = {
      Tipo: ETipoPreferencia.PreferenciaAsientosVuelo,
      Preferencias: tuplaAereoPreferente[0],
      InfoEspecifica: this.obtenerNumero()
    }
    ListaPreferencias.push(preferenciaAereoAsientos)

    let AereoCompanias = this.Grupo_Preferencia_Aereo_Companias.obtenerPreferencias()
    let CompaniasAereo: IPreferenciaCliente = {
      Tipo: ETipoPreferencia.CompaniasAreas,
      Preferencias: AereoCompanias,
      InfoEspecifica: null
    }
    ListaPreferencias.push(CompaniasAereo)


    let textoObservacion = this.Grupo_Preferencia_Aereo_Observaciones.obtenerPreferencias()
    let preferenciaAereoObservaciones: IPreferenciaCliente = {
      Tipo: ETipoPreferencia.ObservacionAereo,
      Preferencias: null,
      InfoEspecifica: textoObservacion,
    }
    ListaPreferencias.push(preferenciaAereoObservaciones)

    return ListaPreferencias;


  }

  //Obter número de fila de asiento en aereo
  obtenerNumero(): string | null {
    let numeroFila: number | null = null;
    if (this.numero !== null && this.numero > 0) {
      numeroFila = this.numero;

    }

    return numeroFila?.toString();
  }

  //Actualziar preferencias aereo
  actualizar() {
    let preferencias = this.clienteFicha.Preferencias
    let preferenciaPreferenciaAsientosVuelo: IPreferenciaCliente = this.obtenerPreferenciasCliente(preferencias, ETipoPreferencia.PreferenciaAsientosVuelo)
    let preferenciaPreferenciaCompaniasAreas: IPreferenciaCliente = this.obtenerPreferenciasCliente(preferencias, ETipoPreferencia.CompaniasAreas)
    let preferenciaTextoObservacionAereo: IPreferenciaCliente = this.obtenerPreferenciasCliente(preferencias, ETipoPreferencia.ObservacionAereo)
    if (preferenciaPreferenciaAsientosVuelo?.InfoEspecifica !== null) {
      this.numero = parseInt(preferenciaPreferenciaAsientosVuelo.InfoEspecifica)
    }

    this.Grupo_Preferencia_Aereo_Preferente.actualizar(preferenciaPreferenciaAsientosVuelo?.Preferencias);
    this.Grupo_Preferencia_Aereo_Companias.actualizarSeleccion(preferenciaPreferenciaCompaniasAreas?.Preferencias)
    this.Grupo_Preferencia_Aereo_Observaciones.actualizar(preferenciaTextoObservacionAereo?.InfoEspecifica)
  }

  //Valdiar preferencias aereo
  validarPreferencias() {
    let estadoAereoPreferente = this.Grupo_Preferencia_Aereo_Preferente.validarPreferencias()
    let estadoAereoCompanias = this.Grupo_Preferencia_Aereo_Companias.validarPreferencias()

    if (estadoAereoPreferente && estadoAereoCompanias) {
      return true;
    }
    return false;
  }
}

