import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { StepsModule } from 'primeng/steps';
import { MatStepperModule } from '@angular/material/stepper';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { NgxFlagIconCssModule } from 'ngx-flag-icon-css';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { registerLocaleData } from '@angular/common';
import { CalendarModule } from 'primeng/calendar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule} from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { TooltipModule } from 'primeng/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { FileUploadModule } from 'primeng/fileupload';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';

// Idiomas
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt-PT';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localeGb from '@angular/common/locales/en-GB';

// Importacion de componentes de terceros
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

// Importacion de componentes creados
import { BanderasModule } from 'src/app/componentes/form-componentes/banderas/banderas.module';
import { InputGroupComponent } from 'src/app/componentes/form-componentes/input-text/input-group.component';
import { BotonComponent } from 'src/app/componentes/form-componentes/boton/boton.component';
import { ImagenDestinuxComponent } from 'src/app/componentes/form-componentes/imagen-destinux/imagen-destinux.component';
import { ImagenFooterLoginComponent } from './componentes/form-componentes/imagen-footer-login/imagen-footer-login.component';
import { LoginComponent } from 'src/app/vistas/auth/login/login.component';
import { LoginAdminComponent } from 'src/app/vistas/auth/login-admin/login-admin.component';
import { ClienteFichaComponent } from './vistas/cliente-ficha/cliente-ficha.component';
import { FichaUsuarioComponent } from './componentes/vista-ficha-usuario/formulario/ficha-usuario/ficha-usuario.component';
import { FormularioUsuarioComponent } from './componentes/vista-ficha-usuario/formulario/formulario-usuario/formulario-usuario.component';
import { InputMaterialComponent } from './componentes/form-componentes/material-framework/input-material/input-material.component';
import { CalendarMaterialComponent } from './componentes/form-componentes/material-framework/calendar-material/calendar-material.component';
import { SeccionPerfilFichaComponent } from './componentes/vista-ficha-usuario/seccion-perfil-ficha/seccion-perfil-ficha.component';
import { SelectSearchMaterialComponent } from './componentes/form-componentes/material-framework/select-search-material/select-search-material.component';
import { CheckboxPrimeNGComponent } from './componentes/form-componentes/primeNG-framework/checkbox-primeNG/checkbox-primeNG.component';
import { UsuarioPreferenciasComponent } from './componentes/vista-ficha-usuario/preferencias/usuario-preferencias/usuario-preferencias.component';
import { PreferenciaAlojamientoComponent } from './componentes/vista-ficha-usuario/preferencias/preferencia-alojamiento/preferencia-alojamiento.component';
import { PreferenciaAereoComponent } from './componentes/vista-ficha-usuario/preferencias/preferencia-aereo/preferencia-aereo.component';
import { PreferenciaTrenComponent } from './componentes/vista-ficha-usuario/preferencias/preferencia-tren/preferencia-tren.component';
import { PreferenciaRentACarComponent } from './componentes/vista-ficha-usuario/preferencias/preferencia-rent-a-car/preferencia-rent-a-car.component';
import { PreferenciasTransferComponent } from './componentes/vista-ficha-usuario/preferencias/preferencias-transfer/preferencias-transfer.component';
import { PreferenciasBarcoComponent } from './componentes/vista-ficha-usuario/preferencias/preferencias-barco/preferencias-barco.component';
import { ObservacionesComponent } from './componentes/form-componentes/observaciones/observaciones.component';


// Importacion de servicios creados
import { ServicioGeneralService } from 'src/app/servicios/servicio-general.service';
import { AlertaService } from 'src/app/shared/alerta.service';

// Importacion de interceptores creados
import { BaseUrlInterceptor } from 'src/app/interceptores/base-url-interceptor.service';
import { HttpErrorInterceptor } from 'src/app/interceptores/http-error-interceptor.service';

// Importacion de Pipes creados
import { FiltroSelectPipe } from 'src/app/shared/filtro-select.pipe';
import { environment } from 'src/environments/environment';
import { SeccionDocumentoComponent } from './componentes/vista-ficha-usuario/formulario/documentos/documento/seccion-documento.component'
import { TarjetaFidelizacionComponent } from './componentes/vista-ficha-usuario/formulario/documentos/tarjetas-fidelizacion/tarjeta-fidelizacion.component';
import { UsuarioPerfilComponent } from './componentes/vista-ficha-usuario/perfil/usuario-perfil/usuario-perfil.component';
import { GrupoPreferenciaComponent } from './componentes/form-componentes/grupo-preferencia/grupo-preferencia.component';
import { ModuloAdministracionComponent } from './vistas/modulo-administracion/modulo-administracion.component';

import { TablaUsuariosComponent } from './componentes/vista-administracion/tabla-usuarios/tabla-usuarios.component';
import { PolPrivComponent } from './vistas/pol-priv/pol-priv.component';
import { CondGeneralesComponent } from './vistas/cond-generales/cond-generales.component';
import { PolSeguridadComponent } from './vistas/pol-seguridad/pol-seguridad.component';
import { PoliticasCondicionesComponent } from './componentes/politicas-condiciones/politicas-condiciones.component';
import { FooterComponent } from './componentes/form-componentes/footer/footer.component';

// Se registran los idiomas soportados
registerLocaleData(localeEs);
registerLocaleData(localePt);
registerLocaleData(localeFr);
registerLocaleData(localeDe);
registerLocaleData(localeGb);


@NgModule({
  declarations: [
    AppComponent,
    InputGroupComponent,
    BotonComponent,
    ImagenDestinuxComponent,
    LoginComponent,
    LoginAdminComponent,
    ImagenFooterLoginComponent,
    ClienteFichaComponent,
    SeccionPerfilFichaComponent,
    FichaUsuarioComponent,
    FormularioUsuarioComponent,
    InputMaterialComponent,
    CalendarMaterialComponent,
    SelectSearchMaterialComponent,
    FiltroSelectPipe,
    SeccionDocumentoComponent,
    TarjetaFidelizacionComponent,
    UsuarioPerfilComponent,
    CheckboxPrimeNGComponent,
    GrupoPreferenciaComponent,
    UsuarioPreferenciasComponent,
    PreferenciaAlojamientoComponent,
    PreferenciaAereoComponent,
    PreferenciaTrenComponent,
    PreferenciaRentACarComponent,
    ObservacionesComponent,
    PreferenciasTransferComponent,
    PreferenciasBarcoComponent,
    ModuloAdministracionComponent,
    TablaUsuariosComponent,
    PolPrivComponent,
    CondGeneralesComponent,
    PolSeguridadComponent,
    PoliticasCondicionesComponent,
    FooterComponent,
    
    ],
  imports: [
    SweetAlert2Module.forRoot(),
    TooltipModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    StepsModule,
    MatStepperModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    ReactiveFormsModule,
    NgxFlagIconCssModule,
    HttpClientModule,
    CalendarModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    CheckboxModule,
    RadioButtonModule,
    DropdownModule,
    MultiSelectModule,
    TableModule,
    FileUploadModule,
    MatMenuModule,
    MatButtonModule,
    MatToolbarModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BanderasModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseUrlInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      deps: [ServicioGeneralService],
      useFactory: (servicioGeneral: any) => {
        return servicioGeneral.getLenguaje();
      }
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true
    },
    AlertaService

  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

/**
 * Método que permite inicializar el idioma de la aplicacion en Español
 * @param translate Servicio de traduccion
 * @returns
 */
export function appInitializerFactory(translate: TranslateService) {
  return () => {
    let langLS = localStorage.getItem('lang');
    if (!langLS) langLS = 'es-ES';
    translate.setDefaultLang(langLS);
    return translate.use(langLS).toPromise();
  };
}

/**
 * Método que permite establecer la ruta donde se obtienen los idiomas
 * @param http Cliente HTTP
 * @returns
 */
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, `${environment.recursos}traducciones/selfbooking/`);
}
