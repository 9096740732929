import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ServiceHttpService } from './service-http.service.';
import { IPeticionAdmin, IRespuestaBuscarEmpresa, IRespuestaListaUsuario } from '../models/interfaces-apis/administracion';
import { IDiccionarios } from '../models/interfaces-apis/diccionarios';

@Injectable({
  providedIn: 'root'
})

/**
 * Servicio que se encarga de gestionar las consultas de los administradores
 */
export class AdminService
{

  /**
   * Constructor de la clase
   * @param _httpService: Servicio http base
   */
  constructor(private _httpService: ServiceHttpService) { }


  /**
   * Método que permite realizar la sincronizacion de cliente desde pre cliente a cliente
   * @returns
   */
  sincronizar(peticion: IPeticionAdmin): Observable<any>
  {
    return this._httpService.postApi<any>('/admin/Sincronizar', peticion);
  }

  /**
   * Método que permite traspasar la informacion de usuarios de un cliente desde la tabla Cliente a Pre Cliente
   * @returns
   */
  cargarEnPreCliente(peticion: IPeticionAdmin): Observable<any>
  {
    return this._httpService.postApi<any>('/admin/CargarEnPreCliente', peticion);
  }

  /**
   * Método que permite cargar la informacion de los pre clientes
   * @returns
   */
  cargarTablaPreCliente(peticion: IPeticionAdmin): Observable<IRespuestaListaUsuario[]>
  {
    return this._httpService.postApi<IRespuestaListaUsuario[]>('/admin/CargarTablaPreClientes', peticion);
  }

  /**
   * Método que permite cargar la informacion de un usuario (Solo info basica del usuario)
   * @returns
   */
  datosCliente(peticion: IPeticionAdmin): Observable<any>
  {
    return this._httpService.getApi<any>('/admin/DatosCliente?idCliente=' + peticion.Cliente);
  }

  /**
   * Método que permite realizar la busqueda de empresas o departamentos en la tabla Cliente
   * @returns
   */
  listarEmpresasPre(buscado: string): Observable<IRespuestaBuscarEmpresa[]>
  {
    return this._httpService.getApi<IRespuestaBuscarEmpresa[]>('/admin/buscar?texto=' + buscado);
  }

  /**
   * Método que permite validar que el usuario que ha iniciado sesion tenga permiso al sistema
   * @returns
   */
  validarGuid(): Observable<any>
  {
    return this._httpService.getApi<any>('/admin/Validar');
  }

  /** Método que permite cargar los diccionarios utilizando la ficha del cliente
  */
  cargarDiccionarios(): Observable<IDiccionarios[]>
  {
    return  this._httpService.getApi<IDiccionarios[]>('/admin/ListaDiccionarios');
  }

  /** Método que permite cerrar sesión
  */
  cerrarSesion(): Observable<any>{
    return this._httpService.getApi<any>('/admin/CerrarSesion');
  }


}
